import {useMutation, useQueryClient} from "react-query";
import axios from "../../api.config";
import {SETTING_TIME_OFF} from "./time-off.query.api";

export function useCreateNewTimeOffType() {
    const queryClient = useQueryClient();
    return useMutation((payload) => axios.post(`/api/v1/admin/paid-leave/type`, payload).then((res) => res.data), {
        onSuccess: () => {
            queryClient.invalidateQueries([SETTING_TIME_OFF.GET_TIME_OFF_LIST]);
            queryClient.invalidateQueries([SETTING_TIME_OFF.GET_TIME_OFF_DETAIL]);
        },
    });
}

export function useUpdateTimeOffType() {
    const queryClient = useQueryClient();
    return useMutation(
        ({timeOffId, ...payload}) =>
            axios.put(`/api/v1/admin/paid-leave/type/${timeOffId}`, payload).then((res) => res.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([SETTING_TIME_OFF.GET_TIME_OFF_LIST]);
                queryClient.invalidateQueries([SETTING_TIME_OFF.GET_TIME_OFF_DETAIL]);
            },
        }
    );
}

export function useDeleteTimeOffType() {
    const queryClient = useQueryClient();
    return useMutation(
        (recordId) => axios.delete(`/api/v1/admin/paid-leave/type/${recordId}`).then((res) => res.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([SETTING_TIME_OFF.GET_TIME_OFF_LIST]);
                queryClient.invalidateQueries([SETTING_TIME_OFF.GET_TIME_OFF_DETAIL]);
            },
        }
    );
}

export function useGetTimeOffDetailData() {
    const queryClient = useQueryClient();
    return useMutation(
        (recordId) => axios.get(`api/v1/admin/paid-leave/type/${recordId}`).then((res) => res.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([SETTING_TIME_OFF.GET_TIME_OFF_LIST]);
                queryClient.invalidateQueries([SETTING_TIME_OFF.GET_TIME_OFF_DETAIL]);
            },
        }
    );
}

import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useLoadScript} from '@react-google-maps/api';

import CONFIG from 'config/env';
import {MainDashboardLayoutComponent} from 'components/main-layout/main.component';
import {BounceLoading} from 'components/loader/bounce.loading';
import {StandartImageComponent} from 'components/image/standart-image.component';
import {PATH_CONSTANT} from 'config/path.constant';
import {useDeleteScheduleUser} from 'api/schedules/jadwal/jadwai.mutation.api';
import {ModalConfirmation} from 'components/modal/moda.confirmation.component';
import {useAttendanceDetails} from 'api/schedules/attendance/attendance.query.api.';
import {MapCoordinateComponent} from './map.component';
import {LocationComponent} from './location.component';
import {TimeComponent} from './time.component';
import {catchErrorMessage} from 'ui-utils/string.utils';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Jadwal & Presensi',
    path: PATH_CONSTANT.PRESENSI.LIST,
  },
  {
    title: 'Presensi',
    path: PATH_CONSTANT.PRESENSI.LIST,
  },
  {
    title: 'Detail Presensixxx',
    path: PATH_CONSTANT.PRESENSI.LIST,
  },
];

export const AttedanceDetailComponent = () => {
  const { userId } = useParams();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: CONFIG.key.GOOGLE_MAP_API,
  });

  const [modalDeleteSchedule, setModalDeleteSchedule] = useState({
    open: false,
    scheduleId: null,
  });

  const { mutate: deleteSchedule, isLoading: isDeleting } = useDeleteScheduleUser();

  const { data: attendanceDetails, isLoading, isSuccess, isError, error } = useAttendanceDetails(userId);

  const handleOnDeleteSchedule = () => {
    deleteSchedule(modalDeleteSchedule.scheduleId, {
      onSuccess: () => {
        setModalDeleteSchedule({
          open: false,
          scheduleId: null,
        });
      },
    });
  };

  return (
    <MainDashboardLayoutComponent
      breadCumbs={BREAD_CUMB_ITEMS}
      isError={isError}
      errorTitle={error?.response?.data?.status}
      erroMessage={catchErrorMessage(error?.response?.data?.message)}
    >
      <>
        {isLoading && (
          <div className='h-[70vh] flex w-full items-center text-center'>
            <BounceLoading color='#5E755A' />
          </div>
        )}

        {isSuccess && (
          <>
            <div class='bg-gray-3 p-5 xl:p-8 rounded-sm flex items-center mb-8'>
              <div class='w-[200px]'>
                <StandartImageComponent
                  // src={attendanceDetails?.data?.userInfo?.profile_picture}
                  withZoom
                  className='w-40 h-40 rounded-full border-8 border-solid border-green-2 object-cover'
                />
              </div>
              <div class='w-[calc(100%-200px)]'>
                <div class='flex items-center'>
                  <div class='w-1/3 border-r border-solid pr-5 border-green'>
                    <div class='text-2xl font-semibold text-green'>{attendanceDetails?.data?.userInfo?.name}</div>
                    <div class='text-sm text-gray font-medium mb-3'>{attendanceDetails?.data?.userInfo?.email}</div>
                  </div>
                  <div class='w-2/3 pl-28 flex flex-col justify-center'>
                    <div className='flex flex-row items-center gap-3'>
                      <StandartImageComponent
                        src={attendanceDetails?.data?.userInfo?.store_image}
                        defaultImage='/img/default-store.jpg'
                        className='w-[60px] h-auto  border-2 border-solid border-green-2 object-cover'
                      />
                      <div className='flex flex-col px-[2%]  w-[calc(100%-60px)] '>
                        <p class='text-md text-green font-medium mb-2'>
                          {attendanceDetails?.data?.userInfo?.store_name}
                        </p>
                        <p class='text-sm text-gray font-medium mb-2'>{attendanceDetails?.data?.userInfo?.city}</p>
                      </div>
                    </div>
                    <div className='flex flex-row items-center w-4/5 gap-3 mt-3'>
                      <div className='w-[60px] text-center'>
                        <div className='icon-ico-calendar text-2xl text-green-3 font-normal '></div>
                      </div>

                      <div className='flex flex-col px-[2%]  w-[calc(100%-60px)]'>
                        <p class='text-md text-green font-medium mb-2'>Tanggal Presensi</p>
                        <p class='text-sm text-gray font-medium mb-2'>
                          {attendanceDetails?.data?.attendanceDetail?.date_formatted}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className='h-[70vh] flex w-full items-center text-center'>
                <BounceLoading color='#5E755A' />
              </div>
            ) : (
              <>
                <div className='mb-5'>
                  <div className='flex flex-row justify-between gap-2 mb-5'>
                    <div class='text-2xl font-semibold text-green mr-10 '>Note & Attachment</div>
                  </div>
                  <div className='border border-gray-1 flex flex-row'>
                    <div className='w-1/2 border-r border-solid border-gray-1'>
                      <div className='p-5'>
                        <TimeComponent
                          time={attendanceDetails?.data?.attendanceDetail?.clock_in?.submit_hour}
                          note={attendanceDetails?.data?.attendanceDetail?.clock_in?.note}
                        />
                        <MapCoordinateComponent
                          lang={attendanceDetails?.data?.attendanceDetail?.clock_in?.longitude}
                          lat={attendanceDetails?.data?.attendanceDetail?.clock_in?.latitude}
                          isLoaded={isLoaded}
                        />
                        <LocationComponent description={attendanceDetails?.data?.attendanceDetail?.clock_in?.address} />
                        <div className='mt-10 mb-8' id='clock-in-picture'>
                          <div className='text-md text-green  font-medium mb-3'>Photos Clock In</div>
                          <div className='flex flex-row flex-wrap gap-2'>
                            {attendanceDetails?.data?.attendanceDetail?.clock_in?.images.length === 0 && (
                              <p className='text-sm text-gray-2  italic'>No Image Available</p>
                            )}
                            {attendanceDetails?.data?.attendanceDetail?.clock_in?.images.map((image) => (
                              <StandartImageComponent
                                defaultImage='/img/image-not-found.png'
                                src={image}
                                withZoom
                                className='h-32 w-auto  border-4 border-border-green'
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='w-1/2 border-r border-solid border-gray-1'>
                      <div className='p-5'>
                        <TimeComponent
                          title='Time Clock Out'
                          type='OUT'
                          time={attendanceDetails?.data?.attendanceDetail?.clock_out?.submit_hour}
                          note={attendanceDetails?.data?.attendanceDetail?.clock_out?.note}
                        />
                        <MapCoordinateComponent
                          title='Coordinate Clock Out'
                          lang={attendanceDetails?.data?.attendanceDetail?.clock_out?.longitude}
                          lat={attendanceDetails?.data?.attendanceDetail?.clock_out?.latitude}
                          isLoaded={isLoaded}
                        />
                        <LocationComponent
                          title='Location Clock Out'
                          description={attendanceDetails?.data?.attendanceDetail?.clock_out?.address}
                        />
                        <div className='mt-10 mb-8' id='clock-in-picture'>
                          <div className='text-md text-green  font-medium mb-3'>Photos Clock Out</div>
                          <div className='flex flex-row flex-wrap gap-2'>
                            {attendanceDetails?.data?.attendanceDetail?.clock_out?.images.length === 0 && (
                              <p className='text-sm text-gray-2  italic'>No Image Available</p>
                            )}
                            {attendanceDetails?.data?.attendanceDetail?.clock_out?.images.map((image) => (
                              <StandartImageComponent
                                defaultImage='/img/image-not-found.png'
                                src={image}
                                withZoom
                                className='h-32 w-auto  border-4 border-border-green'
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>

      <ModalConfirmation
        description='Apakah anda yakin ingin menghapus data ini?'
        title='Delete Data'
        imageIcon='/img/Delete.png'
        textConfirm='Delete'
        loading={isDeleting}
        visible={modalDeleteSchedule.open}
        onConfirm={handleOnDeleteSchedule}
        onClose={() =>
          setModalDeleteSchedule({
            open: false,
          })
        }
      />
    </MainDashboardLayoutComponent>
  );
};

import {useParams} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {StandartImageComponent} from "../../../components/image/standart-image.component";
import {Tooltip} from "flowbite-react";
import {MainDashboardLayoutComponent} from "../../../components/main-layout/main.component";
import {catchErrorMessage} from "../../../ui-utils/string.utils";
import {BounceLoading} from "../../../components/loader/bounce.loading";
import clsx from "clsx";
import {getStatuClassNameByStockTransferStatus} from "../../stock-transfer/stock-transfer.constant";
import {capitalize} from "lodash";
import {BasicTableComponent} from "../../../components/table/table-basic.component";
import {TableButtonExport} from "../../../components/table/table-button-export.component";
import {Select} from "../../../components/form/select.component";
import {ModalConfirmation} from "../../../components/modal/moda.confirmation.component";
import {ModalStockAudit} from "../../report-ba/cycle-count/cycle-count-details/modal-stock-audit.component";
import {
    ModalReportAttachmentComponent
} from "../../report-ba/sales/report-sales-details/modal-report-attachment.component";
import {ModalResult} from "../../../components/modal/modal.result.component";
import {PATH_CONSTANT} from "../../../config/path.constant";

const APPROVE_OPTIONS = [
    {
        id: 'approved',
        name: 'Approve',
    },
    {
        id: 'rejected',
        name: 'Reject',
    },
];

const MODAL_RESULT_DESCRIPTION = {
    approved: {
        title: 'Approve Request',
        description: 'Transfer stock berhasil di setujui!',
    },
    rejected: {
        title: 'Reject Request',
        description: 'Transfer stock berhasil di tolak!',
    },
};

const BREAD_CUMB_ITEMS = [
    {
        title: 'Stock Transfer',
        path: PATH_CONSTANT.STOCK_TRANSFER.LIST,
    },
    {
        title: 'Transfer List',
        path: PATH_CONSTANT.STOCK_TRANSFER.LIST,
    },
    {
        title: 'Detail',
        path: null,
    },
];

export const StockInDetail = (props) => {
    const {id: recordId} = useParams();

    const [modals, setModals] = useState({
        export: false,
        updateStatus: false,
        result: false,
        attachment: false,
        stockAudit: false,
    });
    const [selectedAction, setSelectedAction] = useState(null);
    const {data: stockInDetails, isLoading, isError, error, stockTransferActionLog, isExporting, isApproving} = props
    const {data: stockAuditData, isLoading: isLoadingStockAudit} = props

    const columns = useMemo(
        () => [
            {
                name: 'Product',
                className: 'w-[50%] text-sm font-semibold text-green text-center pr-3',
                dataIndex: 'user',
                render: (record) => (
                    <div className='w-[50%] text-sm font-semibold text-green md:pr-1 pr-3'>
                        <div class='flex items-center pl-2 xl:pl-5'>
                            <div class='w-12'>
                                <StandartImageComponent
                                    src={record?.product_image || '/img/default-product.jpg'}
                                    class='w-12 h-12 object-cover'
                                    alt='Product'
                                />
                            </div>
                            <div class='w-[calc(100%-3rem)] pl-3 xl:pl-5 line-clamp-3'>
                                <Tooltip className='max-w-[30%]' content={record?.product_name}>
                                    <div class='block text-xs font-semibold text-dark'>{record?.product_name}</div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                name: 'Expired Date',
                className:
                    'w-[30%] text-center text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap',
                dataIndex: 'expired_date_formatted',
                render: (record, expired_date_formatted) => (
                    <div class='w-[30%]'>
                        <div class='text-sm text-center font-medium  text-gray'>{expired_date_formatted}</div>
                    </div>
                ),
            },

            {
                name: 'QTY',
                className:
                    'w-[20%] text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap text-center',
                dataIndex: 'qty',
                render: (record, qty) => (
                    <div class='w-[20%]'>
                        <div class='text-sm font-medium text-center text-gray'>{qty ? qty?.toLocaleString() : '0'}</div>
                    </div>
                ),
            },
        ],
        []
    );

    const handleOnApproveTransferStock = () => {
        if (selectedAction) {
            // approveStockTransfer(
            //     {
            //         recordId,
            //         status: selectedAction,
            //     },
            //     {
            //         onSuccess: () => {
            //             setModals({...modals, updateStatus: false, result: true});
            //         },
            //     }
            // );
        }
    };
    const handleExport = () => {
        // exportTransferStockDetailReport(
        //     {recordId},
        //     {
        //         onSuccess: (res) => {
        //             const link = document.createElement('a');
        //             link.href = res.data.download_url;
        //             document.body.appendChild(link);
        //             link.click();
        //             setModals({...modals, export: false});
        //         },
        //     }
        // );
    };

    const handleOnChangeAction = (e) => {
        setSelectedAction(e.target.value);
    };

    const attachments = useMemo(() => {
        if (stockInDetails) {
            return [...stockInDetails?.data?.attachments];
        }
        return [];
    }, [stockInDetails]);

    useEffect(() => {
        if (stockInDetails?.data?.status && stockInDetails?.data?.status !== 'submitted') {
            setSelectedAction(stockInDetails?.data?.status);
        }
    }, [stockInDetails]);

    return (
        <MainDashboardLayoutComponent
            breadCumbs={BREAD_CUMB_ITEMS}
            isError={isError}
            errorTitle={error?.response?.data?.status}
            erroMessage={catchErrorMessage(error?.response?.data?.message)}
        >
            {isLoading || isLoadingStockAudit ? (
                <div className='h-[70vh] flex items-center'>
                    <BounceLoading color='#5E755A'/>
                </div>
            ) : (
                <>
                    <div className='flex w-full justify-between pb-8'>
                        <div className='w-[calc(100%-290px)]'>
                            <div class=' bg-gray-3 px-6 pb-8 mb-5'>
                                <div
                                    className='flex justify-between mb-4 py-2 flex-row items-center  border-solid border-b border-gray-1'>
                                    <div className='flex flex-row items-center'>
                                        <StandartImageComponent
                                            src={stockInDetails?.data?.user_origin_image}
                                            className='w-8 h-8 rounded-full border-2 border-solid border-green-2'
                                        />

                                        <div
                                            class='overflow-hidden ml-2  text-ellipsis whitespace-nowrap text-sm font-medium text-green'>
                                            {stockInDetails?.data?.user_origin_name}
                                        </div>
                                        <div
                                            class={clsx(
                                                'inline-block py-1 px-1 ml-5 rounded-sm  text-sm font-bold',
                                                getStatuClassNameByStockTransferStatus(stockInDetails?.data?.status)
                                            )}
                                        >
                                            {capitalize(stockInDetails?.data?.status)}
                                        </div>
                                    </div>
                                    {stockInDetails?.data?.request_date_formatted && (
                                        <div className='flex flex-row items-center'>
                                            <span class='icon-ico-calendar text-sm text-green-3 font-normal'></span>
                                            <div
                                                class='overflow-hidden ml-2  text-ellipsis whitespace-nowrap text-sm font-medium text-gray'>
                                                {`Requested on ${stockInDetails?.data?.request_date_formatted}`}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='flex items-center justify-around'>
                                    <div class='w-[300px] flex items-center'>
                                        <StandartImageComponent
                                            src={stockInDetails?.data?.store_origin_image}
                                            class='overflow-hidden  object-cover w-[60px] h-[60px] border-2 border-solid border-green-3'
                                        />
                                        <div class='w-[calc(100%-3rem)] pl-3'>
                                            <div
                                                class='overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray'>
                                                Store Pengirim
                                            </div>
                                            <div
                                                class='overflow-hidden block text-ellipsis whitespace-nowrap text-sm font-semibold text-green mb-2'>
                                                {stockInDetails?.data?.store_origin_name}
                                            </div>
                                        </div>
                                    </div>
                                    <div class='w-[300px] flex items-center'>
                                        <StandartImageComponent
                                            src={stockInDetails?.data?.store_destination_image}
                                            class='overflow-hidden object-cover w-[60px] h-[60px] border-2 border-solid border-green-3'
                                        />
                                        <div class='w-[calc(100%-3rem)] pl-3'>
                                            <div
                                                class='overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray'>
                                                Store Penerima
                                            </div>
                                            <div
                                                class='overflow-hidden block text-ellipsis whitespace-nowrap text-sm font-semibold text-green mb-2'>
                                                {stockInDetails?.data?.store_destination_name}
                                            </div>
                                        </div>
                                    </div>
                                    <div class='flex items-center'>
                                        <span class='icon-ico-stock text-[40px] text-green-3 font-normal'></span>
                                        <div class='w-[calc(100%-2rem)] pl-3'>
                                            <div
                                                class='overflow-hidden font-bold text-ellipsis whitespace-nowrap text-sm  text-gray'>
                                                {`Total ${stockInDetails?.data?.total_qty?.toLocaleString()} products`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className='italic text-dark text-sm mb-5'>{`Total ${stockInDetails?.data?.items?.length} items`}</div>
                            <BasicTableComponent
                                rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
                                columns={columns}
                                dataSource={stockInDetails?.data?.items || []}
                            />
                        </div>
                        <div class='w-[270px]'>
                            <TableButtonExport
                                onClick={handleExport}
                                loading={isExporting}
                                title='Export Data'
                                className='outline-none w-full flex items-center border justify-center border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3 mb-3'
                            />

                            {selectedAction !== 'completed' && (
                                <div class='border border-solid border-gray-1 rounded-sm p-2 mb-3'>
                                    <div class='block text-sm font-semibold text-dark mb-3'>Transfer Confirmation</div>

                                    <Select
                                        onChange={handleOnChangeAction}
                                        name='type'
                                        withNativeDownIcon
                                        containerClassName='mb-4 relative'
                                        placeholder='Select Action'
                                        options={APPROVE_OPTIONS}
                                        value={selectedAction}
                                    />
                                    <button
                                        class='bg-green flex
                  disabled:opacity-50 disabled:cursor-not-allowed
                  justify-center items-center w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                                        type='button'
                                        disabled={!selectedAction}
                                        onClick={() => {
                                            setModals((prev) => ({
                                                ...prev,
                                                updateStatus: true,
                                            }));
                                        }}
                                    >
                                        <span class='icon-ico-apply mr-3 text-lg'></span> Apply
                                    </button>
                                </div>
                            )}

                            <div class='bg-gray-3 py-5 px-4 mb-3'>
                                <div class='block text-sm font-semibold text-dark mb-6'>Transfer Log</div>

                                <ul class='step max-h-[45vh] scroll'>
                                    {stockTransferActionLog?.data?.map((actionLogItem) => (
                                        <li>
                                            <div class='bullet'></div>
                                            <div
                                                class='text-sm font-semibold text-gray '>{actionLogItem?.action_description}</div>
                                            <div
                                                class='text-xs font-semibold italic text-gray mb-2'>{`Modified by ${actionLogItem?.actor_email}`}</div>
                                            <div
                                                class='text-sm font-medium italic text-gray'>{actionLogItem?.created_at_formatted}</div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div class='bg-gray-3 py-5 px-4 max-h-[45vh] scroll'>
                                <div class='block text-sm font-semibold text-dark mb-3'>Notes BA</div>
                                <p class='text-gray text-sm mb-4 whitespace-pre-line'>{stockInDetails?.data?.note}</p>

                                <div className='flex flex-row flex-wrap gap-2 mb-3'>
                                    {stockInDetails?.data?.images?.map((image) => (
                                        <StandartImageComponent
                                            defaultImage='/img/image-not-found.png'
                                            src={image?.image_url}
                                            withZoom
                                            className='h-12 w-auto  border-2 border-border-green'
                                        />
                                    ))}
                                </div>
                                {attachments.length > 0 && (
                                    <button
                                        class='outline-none w-full flex items-center border  border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
                                        type='button'
                                        onClick={() => {
                                            setModals((prev) => ({
                                                ...prev,
                                                attachment: true,
                                            }));
                                        }}
                                    >
                                        <span class='icon-ico-attachment text-xl text-green'></span>
                                        <span class='pl-2 font-semibold text-dark text-sm'>Attachment</span>
                                    </button>
                                )}
                                <button
                                    class='bg-green flex  my-2 justify-center items-center w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                                    type='button'
                                    onClick={() => {
                                        setModals((prev) => ({
                                            ...prev,
                                            stockAudit: true,
                                        }));
                                    }}
                                >
                                    <span class='icon-ico-info mr-3 text-lg'></span> Stock Audit
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <ModalConfirmation
                description='Apakah anda yakin ingin mengubah status stock transfer ini ?'
                title='Update Stock Transfer Status'
                imageIcon='/img/info.svg'
                visible={modals.updateStatus}
                loading={isApproving}
                textConfirm='Update'
                onConfirm={handleOnApproveTransferStock}
                onClose={() => {
                    setSelectedAction(stockInDetails?.data?.status);
                    setModals({...modals, updateStatus: false});
                }}
            />

            <ModalStockAudit
                visible={modals.stockAudit}
                onClose={() => setModals({...modals, stockAudit: false})}
                data={stockAuditData?.data}
            />

            <ModalReportAttachmentComponent
                visible={modals.attachment}
                title='Attachments'
                attachments={attachments}
                onClose={() => {
                    setModals((prev) => ({...prev, attachment: false}));
                }}
            />
            <ModalResult
                description={MODAL_RESULT_DESCRIPTION?.[selectedAction]?.description}
                title={MODAL_RESULT_DESCRIPTION?.[selectedAction]?.title}
                visible={modals.result}
                textConfirm='OK'
                onConfirm={() => setModals({...modals, result: false})}
                onClose={() => setModals({...modals, result: false})}
            />
        </MainDashboardLayoutComponent>
    );
}

export const PATH_CONSTANT = {
  HOME: '/',
  AUTH: {
    LOGIN: '/auth/login',
    FORGOT_PASSWORD: '/auth/forgot-password',
  },
  USER: {
    USER_LIST: '/users/list',
    USER_CREATE: '/users/create',
    USER_EDIT: '/users/list/edit',
    USER_DETAILS: '/users/list/details',
  },
  REPORT_BA: {
    SALES: '/report-ba/sales',
    DETAILS: '/report-ba/sales/details',
    VISITOR: '/report-ba/visitor',
    NED_PRODUCT: '/report-ba/ned-product',
    NED_PRODUCT_DETAILS: '/report-ba/ned-product/details',
    CYCLE_COUNT: '/report-ba/cycle-count',
    CYCLE_COUNT_DETAILS: '/report-ba/cycle-count/details',
  },
  STORE: {
    STORE_LIST: '/store/list',
    STORE_CREATE: '/store/create',
    STORE_EDIT: '/store/list/edit',
    STORE_DETAILS: '/store/list/details',
  },
  PRODUCT: {
    PRODUCT_LIST: '/product/list',
    PRODUCT_CREATE: '/product/list/create',
    PRODUCT_EDIT: '/product/list/edit',
    PRODUCT_DETAILS: '/product/list/details',
    PRODUCT_STOCK_STORE_LIST: '/product/stock-store/list',
    PRODUCT_STOCK_STORE_INFO: '/product/stock-store/list/info',
    PRODUCT_IMPORT: '/product/list/import',
    PRODUCT_STOCK_STORE_IMPORT: '/product/stock-store/list/import',
  },
  ANALYTICS: {
    SALES: '/analytics/analytic-sales',
    PRODUCT: '/analytics/analytic-product',
    PRESENSI: '/analytics/analytic-presensi',
    TRAFFIC: '/analytics/analytic-traffic',
  },
  SETTINGS: {
    MOBILE_VERSION: '/settings/mobile-app-version',
    REQUEST_CUTI: '/settings/request-cuti',
  },
  STOCK_MOVEMENT: {
    PO_LIST: '/stock-movement/pre-order',
    PO_CREATE: '/stock-movement/pre-order/create',
    PO_EDIT: '/stock-movement/pre-order/edit',
    APPROMENT_LIST: '/stock-movement/approvement',
    APPROMENT_DETAILS: '/stock-movement/approvement/details',
    APPROVEMENT_PO_DETAILS: '/stock-movement/approvement/po-details',
    PO_DETAILS: '/stock-movement/pre-order/details',
    PICKING_LIST: '/stock-movement/picking',
    PICKING_INPUT_EXPIRED_DATE: '/stock-movement/picking/input-expired-date',
    PICKING_PRINT_REQUEST_PO: '/stock-movement/picking/print-request-po',
    PICKING_DETAILS: '/stock-movement/picking/details',
    PACKING_LIST: '/stock-movement/packing',
    PACKING_DETAILS: '/stock-movement/packing/details',
    OUTBOND_LIST: '/stock-movement/outbound',
    OUTBOND_DETAILS: '/stock-movement/outbound/details',
  },
  STOCK_TRANSFER: {
    LIST: '/stock-transfer/list',
    DETAILS: '/stock-transfer/details',
  },
  STOCK_IN: {
    LIST: '/stock-in/list',
    DETAILS: '/stock-in/details',
  },
  SCHEDULE: {
    LIST: '/schedule/schedule',
    PRESENCE: '/schedule/presence',
    DETAILS: '/schedule/schedule/details',
    IMPORT: '/schedule/schedule/import',
  },
  PRESENSI: {
    LIST: '/schedule/presensi',
    DETAILS: '/schedule/presensi/details',
  },
  GANTI_JADWAL: {
    LIST: '/schedule/ganti-jadwal',
    DETAILS: '/schedule/ganti-jadwal/details',
  },
  CUTI: {
    LIST: '/schedule/time-off',
  },
  OVERTIME: {
    LIST: '/schedule/overtime',
    DETAILS: '/schedule/overtime/details',
  },
  RETURN_PRODUCT: {
    LIST: '/return-product',
    DETAILS: '/return-product/list/details/:recordId',
  },
  SHARE_INFO: {
    CREATE_INFO: '/share-info/create-info',
    EDIT_INFO: '/share-info/info/edit/:recordId',
    DETAILS_INFO: '/share-info/info/details/:recordId',
    DETAILS_SKILL_DEVELOPMENT: '/share-info/skill-development/details/:recordId',
    LIST_INFO: '/share-info/info/list',
    CREATE_INFO_SKILL_DEVELOPMENT: '/share-info/create-skill-development',
    EDIT_INFO_SKILL_DEVELOPMENT: '/share-info/skill-development/:recordId/edit',
    LIST_INFO_SKILL_DEVELOPMENT: '/share-info/skill-development/list',
  },
};

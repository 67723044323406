import axios from 'api/api.config';
import {useQuery} from 'react-query';

export const SETTING_TIME_OFF = {
    GET_TIME_OFF_LIST: 'GET_TIME_OFF_LIST',
    GET_TIME_OFF_DETAIL: 'GET_TIME_OFF_DETAIL'
};

export function GetTimeOffList() {
    return useQuery([SETTING_TIME_OFF.GET_TIME_OFF_LIST], () =>
        axios.get(`api/v1/admin/paid-leave/type`).then((res) => res.data)
    );
}

export function useGetTimeOffDetail(recordId) {
    return useQuery([SETTING_TIME_OFF.GET_TIME_OFF_DETAIL, recordId], () =>
        axios.get(`api/v1/admin/paid-leave/type/${recordId}`).then((res) => res.data)
    );
}

import {useMutation, useQueryClient} from "react-query";
import axios from "../../api.config";
import qs from "query-string";
import {TIME_OFF_REQUEST_QUERY_KEY} from "./time-off.query.api";

export function useExportTimeOffList() {
    return useMutation(({ queryFilter, bodyFilter }) =>
        axios.post(`/api/v1/admin/paid-leave/export?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
    );
}

export function useUpdateTimeOffStatus() {
    const queryClient = useQueryClient();
    return useMutation(
        (payload) => axios.put(`/api/v1/admin/paid-leave/approve`, payload).then((res) => res.data),
        {
            onSuccess: () => {
                queryClient.removeQueries([TIME_OFF_REQUEST_QUERY_KEY.TIME_OFF_REQUEST_LIST]);
            },
        }
    );
}


import {ModalContainer} from "../../../components/modal/modal-container.component";
import {Formik} from "formik";
import {InputComponent} from "../../../components/form/input.component";
import {ButtonSubmit} from "../../../components/button/button-submit.component";
import {ModalResult} from "../../../components/modal/modal.result.component";
import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import * as Yup from "yup";
import {
    useCreateNewTimeOffType,
    useGetTimeOffDetailData,
    useUpdateTimeOffType
} from "../../../api/settings/time-off/time-off.mutation.api";
import {Select} from "../../../components/form/select.component";

const formInitialValues = {
    name: null,
    max_request: null,
    quota_per_year: null,
    max_days_per_request: null,
    min_days_per_request: null,
    is_need_one_year_contract: false,
    is_allow_years_intersect: false
};

const formValidationSchema = Yup.object().shape({
    name: Yup.string().required('Nama harus diisi'),
});

export const MODAL_CREATE_TIME_OFF_TYPE = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
};

export const MODAL_LABEL_TEXT = {
    [MODAL_CREATE_TIME_OFF_TYPE.CREATE]: {
        title: 'Tambah Tipe Cuti',
        submitButton: 'Apply',
        modalResultTitle: 'Tipe Cuti Created',
        modalResultDescription: 'Tipe cuti created successfully!',
    },
    [MODAL_CREATE_TIME_OFF_TYPE.UPDATE]: {
        title: 'Ubah Tipe Cuti',
        submitButton: 'Update',
        modalResultTitle: 'Tipe Cuti Updated',
        modalResultDescription: 'Tipe Cuti updated successfully!',
    },
};

const OPTIONS = [{name: 'Ya', id: true}, {name: 'Tidak', id: false}]

export default function CreateTimeOffTypeModal(props) {
    const {
        visible,
        onClose,
        type = MODAL_CREATE_TIME_OFF_TYPE.CREATE,
        typeId = null,
    } = props
    const formikRef = useRef(null);
    const {userId} = useParams();
    const [showModalResult, setShowModalResult] = useState(false);
    const {mutate: getDetailTimeOff, isLoading: isReceiving} = useGetTimeOffDetailData();
    const {mutate: createTimeOffType, isLoading: isCreating} = useCreateNewTimeOffType();
    const {mutate: updateTimeOffType, isLoading: isUpdating} = useUpdateTimeOffType();
    const [modalResultType, setModalResultType] = useState(MODAL_CREATE_TIME_OFF_TYPE.CREATE);

    useEffect(() => {
        if (typeId) {
            getDetailTimeOff(typeId, {
                onSuccess: (resp) => {
                    formikRef.current.setValues({
                        name: resp?.data?.name,
                        max_request: resp?.data?.max_request,
                        quota_per_year: resp?.data?.quota_per_year,
                        max_days_per_request: resp?.data?.max_days_per_request,
                        is_need_one_year_contract: resp?.data?.is_need_one_year_contract,
                        min_days_per_request: resp?.data?.min_days_per_request,
                        is_allow_years_intersect: resp?.data?.is_allow_years_intersect
                    });
                },
            })
        }

    }, [typeId]);

    const handleSubmitForm = (data) => {
        if (type === MODAL_CREATE_TIME_OFF_TYPE.CREATE) {
            createTimeOffType(
                {
                    user_id: userId,
                    ...data,
                },
                {
                    onSuccess: () => {
                        handleOnClose();
                        setShowModalResult(true);
                        setModalResultType(MODAL_CREATE_TIME_OFF_TYPE.CREATE);
                    },
                }
            );
        }
        if (type === MODAL_CREATE_TIME_OFF_TYPE.UPDATE) {
            updateTimeOffType(
                {
                    timeOffId: typeId,
                    ...data,
                },
                {
                    onSuccess: () => {
                        handleOnClose();
                        setShowModalResult(true);
                        setModalResultType(MODAL_CREATE_TIME_OFF_TYPE.UPDATE);
                    },
                }
            );
        }
    };

    const handleOnClose = () => {
        formikRef.current.setValues(formInitialValues);
        onClose();
    };

    const isMutating = isCreating || isUpdating;

    return (
        <>
            <ModalContainer visible={visible}>
                <Formik
                    innerRef={formikRef}
                    initialValues={formInitialValues}
                    validationSchema={formValidationSchema}
                    onSubmit={handleSubmitForm}
                >
                    {({values, handleChange, handleSubmit}) => (
                        <>
                            <div id='status' class='w-[330px] bg-white rounded-sm p-5 relative'>
                                <div class='mb-5 text-center font-bold text-sm'>{MODAL_LABEL_TEXT[type].title}</div>

                                <button onClick={handleOnClose} type='button' class='absolute top-2 right-3'>
                                    <span class='icon-ico-close text-xl text-gray-2'></span>
                                </button>
                                <div class='max-h-[45vh] scroll mb-3'>
                                    <InputComponent
                                        name='name'
                                        disabled={isMutating}
                                        onChange={handleChange}
                                        value={values.name}
                                        label='Nama Tipe Cuti'
                                        placeholder={'Cuti tahunan'}
                                        type='text'
                                    />
                                    <InputComponent
                                        name='max_request'
                                        disabled={isMutating}
                                        onChange={handleChange}
                                        value={values.max_request}
                                        label='Maksimum Request'
                                        placeholder={'0'}
                                        type='number'
                                        min={0}
                                    />
                                    <InputComponent
                                        name='quota_per_year'
                                        disabled={isMutating}
                                        onChange={handleChange}
                                        value={values.quota_per_year}
                                        label='Kuota Per Tahun'
                                        placeholder={'0'}
                                        type='number'
                                        min={0}
                                    />
                                    <InputComponent
                                        name='max_days_per_request'
                                        disabled={isMutating}
                                        onChange={handleChange}
                                        value={values.max_days_per_request}
                                        label='Maksimal hari per request'
                                        placeholder={'0'}
                                        type='number'
                                        min={0}
                                    />
                                    <InputComponent
                                        name='min_days_per_request'
                                        disabled={isMutating}
                                        onChange={handleChange}
                                        value={values.min_days_per_request}
                                        label='Minimal hari per request'
                                        placeholder={'0'}
                                        type='number'
                                        min={0}
                                    />
                                    <Select
                                        name='is_need_one_year_contract'
                                        onChange={handleChange}
                                        value={values.is_need_one_year_contract}
                                        options={OPTIONS}
                                        containerClassName='form-input  relative mb-3'
                                        label='Cuti tahunan'
                                    />
                                    <Select
                                        name='is_allow_years_intersect'
                                        onChange={handleChange}
                                        value={values.is_allow_years_intersect}
                                        options={OPTIONS}
                                        containerClassName='form-input  relative mb-3'
                                        label='Boleh beda tahun ?'
                                    />
                                </div>

                                <div class='flex justify-between items-center pb-2'>
                                    <div class='w-[48%]'>
                                        <button
                                            type='button'
                                            onClick={handleOnClose}
                                            class='block w-full rounded-sm border border-solid border-gray-1 py-2 px-2 text-sm font-semibold text-dark hover:bg-green-status transition duration-75 ease-in-out'
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div class='w-[48%]'>
                                        <ButtonSubmit
                                            onClick={handleSubmit}
                                            disabled={isMutating || !formValidationSchema.isValidSync(values)}
                                            loading={isCreating || isUpdating}
                                            className='bg-green w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                                            type='button'
                                        >
                                            {MODAL_LABEL_TEXT[type].submitButton}
                                        </ButtonSubmit>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </Formik>
            </ModalContainer>

            <ModalResult
                description={MODAL_LABEL_TEXT[modalResultType].modalResultDescription}
                title={MODAL_LABEL_TEXT[modalResultType].modalResultTitle}
                visible={showModalResult}
                textConfirm='OK'
                onConfirm={() => setShowModalResult(false)}
                onClose={() => setShowModalResult(false)}
            />
        </>
    )
}

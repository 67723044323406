import {useQuery} from "react-query";
import axios from "../../api.config";
import qs from "query-string";

export const TIME_OFF_REQUEST_QUERY_KEY = {
    TIME_OFF_REQUEST_LIST: 'TIME_OFF_REQUEST_LIST',
    TIME_OFF_REQUEST_DETAILS: 'TIME_OFF_REQUEST_DETAILS',
};

export function useGetTimeOffRequestList({ filter, bodyFilter }) {
    return useQuery([TIME_OFF_REQUEST_QUERY_KEY.TIME_OFF_REQUEST_LIST, { filter, bodyFilter }], () =>
        axios.post(`/api/v1/admin/paid-leave?${qs.stringify(filter)}`, bodyFilter).then((res) => res.data)
    );
}

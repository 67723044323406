import clsx from 'clsx';
import { ModalContainer } from 'components/modal/modal-container.component';

export function ModalReportAttachmentComponent({ visible, onClose, note = null, attachments, title = 'Report Attachments' }) {
  return (
    <ModalContainer visible={visible}>
      <div id='show-item' class={clsx('w-[330px] bg-white rounded-sm p-5 relative', !visible && 'hidden')}>
        <div class='relative text-center py-4'>
          <div class='mb-5 text-center font-bold text-sm'>{title}</div>
          <button type='button' class='absolute top-0 right-0 border-0' onClick={onClose}>
            <span class='icon-ico-close'></span>
          </button>
        </div>

        <div class='max-h-[45vh] scroll mb-2'>
          {
            !!note &&
                <div className={'flex items-start mb-4'}>{note}</div>
          }
          {attachments.map((item, index) => (
            <div class='flex items-center p-1' key={item.url || item.file_url || item.image_url}>
              <button
                class='outline-none w-full flex items-center border  border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
                type='button'
                onClick={() => {
                  window.open(item.url || item.file_url || item.image_url, '_blank');
                }}
              >
                <span class='icon-ico-attachment text-xl text-green'></span>
                <span class='pl-2 font-semibold text-dark text-sm'>{`Attachment ${index + 1}`}</span>
              </button>
            </div>
          ))}
        </div>
      </div>
    </ModalContainer>
  );
}

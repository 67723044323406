import {MainDashboardLayoutComponent} from "../../../components/main-layout/main.component";
import {PATH_CONSTANT} from "../../../config/path.constant";
import {BounceLoading} from "../../../components/loader/bounce.loading";
import {BasicTableComponent} from "../../../components/table/table-basic.component";
import {catchErrorMessage} from "../../../ui-utils/string.utils";
import {useMemo, useState} from "react";
import {GetTimeOffList} from "../../../api/settings/time-off/time-off.query.api";
import {ButtonSubmit} from "../../../components/button/button-submit.component";
import CreateTimeOffTypeModal, {MODAL_CREATE_TIME_OFF_TYPE} from "./create-time-off-type.modal";
import {ModalConfirmation} from "../../../components/modal/moda.confirmation.component";
import {useDeleteTimeOffType} from "../../../api/settings/time-off/time-off.mutation.api";
import {Tooltip} from "flowbite-react";

const BREAD_CRUMB_ITEMS = [
    {
        title: 'Settings',
        path: PATH_CONSTANT.SETTINGS.REQUEST_CUTI,
    },
    {
        title: 'Request Cuti',
        path: PATH_CONSTANT.SETTINGS.REQUEST_CUTI,
    },
];

export default function TimeOffSetting() {
    const {data: timeOffList, isLoading, isError, error} = GetTimeOffList();
    const [modalAddSchedule, setModalAddSchedule] = useState({
        open: false,
        operationType: MODAL_CREATE_TIME_OFF_TYPE.CREATE,
        typeId: null,
    });
    const [modalDelete, setModalDelete] = useState({
        open: false,
        typeId: null,
    });
    const {mutate: deleteTimeOffType, isLoading: isDeleting} = useDeleteTimeOffType();

    const columns = useMemo(
        () => [
            {
                name: 'Tipe Cuti',
                className: 'w-[20%] text-sm font-semibold text-green pl-6',
                dataIndex: 'name',
                render: (record) => (
                    <div class='w-[20%]'>
                        <div
                            class='text-sm font-medium text-gray pl-6'>{record?.name}</div>
                    </div>
                ),
            },
            {
                name: 'Min Request',
                className: 'w-[15%] justify-center flex text-sm font-semibold text-green text-center',
                dataIndex: 'max_request',
                renderHeader: (columnConfig, index) => {
                    return (
                        <div key={`${columnConfig.name}-${index}`} class={columnConfig.className}>
                            {columnConfig.name}
                            <Tooltip
                                className='max-w-[20%]'
                                content='Merupakan jumlah minimum request cuti yang di perbolehkan selama bekerja'
                            >
                                <button class='p-0 outline-none ml-2 text-green' type='button'>
                                    <span class='icon-ico-help'></span>
                                </button>
                            </Tooltip>
                        </div>
                    );
                },
                render: (record) => (
                    <div class='w-[15%]'>
                        <div
                            class='text-sm font-medium text-gray text-center'>{!!record?.min_request ? `${record.min_request} kali` :
                            <span>&#8734;</span>}
                        </div>
                    </div>
                ),
            },
            {
                name: 'Max Request',
                className: 'w-[15%] justify-center flex text-sm font-semibold text-green text-center',
                dataIndex: 'max_request',
                renderHeader: (columnConfig, index) => {
                    return (
                        <div key={`${columnConfig.name}-${index}`} class={columnConfig.className}>
                            {columnConfig.name}
                            <Tooltip
                                className='max-w-[20%]'
                                content='Merupakan jumlah maximum request cuti yang di perbolehkan selama bekerja'
                            >
                                <button class='p-0 outline-none ml-2 text-green' type='button'>
                                    <span class='icon-ico-help'></span>
                                </button>
                            </Tooltip>
                        </div>
                    );
                },
                render: (record) => (
                    <div class='w-[15%]'>
                        <div
                            class='text-sm font-medium text-gray text-center'>{!!record?.max_request ? `${record.max_request} kali` :
                            <span>&#8734;</span>}
                        </div>
                    </div>
                ),
            },
            {
                name: 'Maksimal Hari Per Request',
                className: 'w-[15%] justify-center flex text-sm font-semibold text-green text-center',
                dataIndex: 'max_days_per_request',
                renderHeader: (columnConfig, index) => {
                    return (
                        <div key={`${columnConfig.name}-${index}`} class={columnConfig.className}>
                            {columnConfig.name}
                            <Tooltip
                                className='max-w-[20%]'
                                content='Maksimal pengambilan cuti untuk 1 kali request '
                            >
                                <button class='p-0 outline-none ml-2 text-green' type='button'>
                                    <span class='icon-ico-help'></span>
                                </button>
                            </Tooltip>
                        </div>
                    );
                },
                render: (record) => (
                    <div class='w-[15%]'>
                        <div
                            class='text-sm font-medium text-gray text-center'>{record?.max_days_per_request ? `${record?.max_days_per_request} hari` :
                            <span>&#8734;</span>}
                        </div>
                    </div>
                ),
            },
            {
                name: 'Minimum Hari Per Request',
                className: 'w-[15%] justify-center flex text-sm font-semibold text-green text-center',
                dataIndex: 'min_days_per_request',
                renderHeader: (columnConfig, index) => {
                    return (
                        <div key={`${columnConfig.name}-${index}`} class={columnConfig.className}>
                            {columnConfig.name}
                            <Tooltip
                                className='max-w-[20%]'
                                content='Minimum pengambilan cuti untuk 1 kali request '
                            >
                                <button class='p-0 outline-none ml-2 text-green' type='button'>
                                    <span class='icon-ico-help'></span>
                                </button>
                            </Tooltip>
                        </div>
                    );
                },
                render: (record) => (
                    <div class='w-[15%]'>
                        <div
                            class='text-sm font-medium text-gray text-center'>{record?.min_days_per_request ? `${record?.min_days_per_request} hari` :
                            <span>&#8734;</span>}
                        </div>
                    </div>
                ),
            },
            {
                name: 'Kuota Per Tahun',
                className: 'w-[10%] justify-center flex text-sm font-semibold text-green text-center',
                dataIndex: 'quota_per_year',
                renderHeader: (columnConfig, index) => {
                    return (
                        <div key={`${columnConfig.name}-${index}`} class={columnConfig.className}>
                            {columnConfig.name}
                            <Tooltip
                                className='max-w-[20%]'
                                content='Kuota cuti yang di dapatkan untuk tipe tersebut selama 1 tahun '
                            >
                                <button class='p-0 outline-none ml-2 text-green' type='button'>
                                    <span class='icon-ico-help'></span>
                                </button>
                            </Tooltip>
                        </div>
                    );
                },
                render: (record) => (
                    <div class='w-[10%]'>
                        <div
                            className='text-sm font-medium text-gray text-center'>{record?.quota_per_year ? `${record?.quota_per_year} hari` :
                            <span>&#8734;</span>}
                        </div>
                    </div>
                ),
            },
            {
                name: 'Cuti Tahunan',
                className: 'w-[10%] justify-center flex text-sm font-semibold text-green text-center',
                dataIndex: 'is_need_one_year_contract',
                renderHeader: (columnConfig, index) => {
                    return (
                        <div key={`${columnConfig.name}-${index}`} class={columnConfig.className}>
                            {columnConfig.name}
                            <Tooltip
                                className='max-w-[20%]'
                                content='Cuti didapatkan ketika telah bekerja selama 1 tahun '
                            >
                                <button class='p-0 outline-none ml-2 text-green' type='button'>
                                    <span class='icon-ico-help'></span>
                                </button>
                            </Tooltip>
                        </div>
                    );
                },
                render: (record) => (
                    <div class='w-[10%]'>
                        <div
                            class='text-sm font-medium text-gray text-center'>{record?.is_need_one_year_contract ? 'Ya' : 'Tidak'}
                        </div>
                    </div>
                ),
            },
            {
                name: 'Action',
                className: 'w-[15%] text-sm font-semibold text-green text-center',
                dataIndex: '',
                render: (record) => (
                    <div class='w-[15%] flex justify-center space-x-2'>
                        <div
                            onClick={() => onUpdateHandler(record)}
                            class={'inline-block bg-green-status text-center w-28 py-1 px-1 rounded-sm text-green text-sm font-medium'}>
                            Update
                        </div>
                        <p>|</p>
                        <div
                            onClick={() => onDeleteHandler(record)}
                            className={'inline-block bg-yellow-light text-center w-28 py-1 px-1 rounded-sm text-black text-sm font-medium'}>
                            Delete
                        </div>
                    </div>
                ),
            },
        ],
        []
    );

    const onUpdateHandler = (record) => {
        setModalAddSchedule((prev) => ({
            open: true,
            operationType: MODAL_CREATE_TIME_OFF_TYPE.UPDATE,
            typeId: record?.id,
        }));
    }

    const onDeleteHandler = (record) => {
        setModalDelete({
            open: true,
            typeId: record?.id,
        });
    }

    const handleDelete = () => {
        deleteTimeOffType(modalDelete.typeId, {
            onSuccess: () => {
                setModalDelete({
                    open: false,
                    typeId: null,
                });
            },
        });
    }


    return (
        <MainDashboardLayoutComponent breadCumbs={BREAD_CRUMB_ITEMS}>
            <div className={'flex justify-end'}>
                <div className='w-[300px] py-6'>
                    <ButtonSubmit
                        disabled={isLoading}
                        onClick={() => {
                            setModalAddSchedule((prev) => ({
                                ...prev,
                                open: true,
                            }));
                        }}
                        className='bg-green w-full flex items-center justify-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                    >
                        <span className='icon-ico-plus-circle text-[20px] leading-[21px] mr-3'></span>
                        Tambah Tipe Cuti
                    </ButtonSubmit>
                </div>
            </div>
            {isLoading ?
                <div className='h-[70vh] flex items-center'>
                    <BounceLoading color='#5E755A'/>
                </div>
                :
                <BasicTableComponent
                    rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
                    columns={columns}
                    dataSource={timeOffList?.data || []}
                    loading={isLoading}
                    error={isError}
                    errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
                    errorMessage={catchErrorMessage(error?.response?.data?.message)}
                />
            }
            <CreateTimeOffTypeModal
                visible={modalAddSchedule.open}
                onClose={() => setModalAddSchedule({open: false, typeId: null})}
                type={modalAddSchedule.operationType}
                typeId={modalAddSchedule.typeId}
            />
            <ModalConfirmation
                description='Apakah anda yakin ingin menghapus data tipe request cuti yang anda pilih?'
                title='Hapus Tipe Cuti'
                visible={modalDelete.open}
                onConfirm={handleDelete}
                textConfirm={'Hapus'}
                onClose={() => setModalDelete({open: false, typeId: null})}
            />
        </MainDashboardLayoutComponent>
    )
}

import qs from 'query-string';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonLimitComponent } from 'components/table/table-button-limit.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { TableButtonFilterComponent } from 'components/table/table-button-filter.component';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { Pagination } from 'components/table/table-pagination.component';
import { EmptyStateComponent } from 'components/empty-data';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { ModalTableLimitComponent } from 'components/modal/modal-table-limit.component';
import { ModalTableFilter } from 'components/modal/modal-table-filter.component';
import { ModalResult } from 'components/modal/modal.result.component';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { RangeDatePicker } from 'components/date-picker/range-date-picker.component';
import { useReportBAVisitorList } from 'api/report-ba/visitor/visitor.query.api';
import { useExportReportVisitor } from 'api/report-ba/visitor/visitor.mutation.api';
import { isAllPropertyObjectFalsy } from 'ui-utils/object.utils';
import { catchErrorMessage, isMaxWords, trucanateWords } from 'ui-utils/string.utils';
import { createAndClickLink } from 'ui-utils/dom.utils';
import { setToolsReducer } from 'reducers/tools.reducer';
import { PATH_CONSTANT } from 'config/path.constant';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { HighlightedText } from 'components/highlighted-text';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Report BA',
    path: PATH_CONSTANT.REPORT_BA.VISITOR,
  },
  {
    title: 'Visitor',
    path: PATH_CONSTANT.REPORT_BA.VISITOR,
  },
];

const INITIAL_VALUE_FILTER_BODY = {
  status: null,
  store_ids: [],
};

const SORT_OPTIONS = [
  { label: 'Urutkan Nama BA A-Z', sortType: 'asc', sortBy: 'user_name' },
  { label: 'Urutkan Nama BA Z-A', sortType: 'desc', sortBy: 'user_name' },
  { label: 'Tanggal Report Terbaru - Terdahulu', sortType: 'desc', sortBy: 'report_date' },
  { label: 'Tanggal Report Terdahulu - Terbaru', sortType: 'asc', sortBy: 'report_date' },
];

const getFilteredSearchParam = (searchParams, key, regex, defaultValue) => {
  return searchParams.get(key)?.replace(regex, '') || defaultValue;
};

const getDefaultStartDate = () => moment().subtract(1, 'month').toDate();
const getDefaultEndDate = () => moment().toDate();

export const ReportBAVisitorListComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const startDateParams = searchParams.get('startDate') || null;
  const endDateParams = searchParams.get('endDate') || null;
  const INITIAL_VALUE_FILTER_QUERY = {
    limit: getFilteredSearchParam(searchParams, 'limit', /[^0-9]/g, 20),
    page: getFilteredSearchParam(searchParams, 'page', /[^0-9]/g, 1),
    orderBy: getFilteredSearchParam(searchParams, 'orderBy', /[^a-zA-Z_]/g, 'report_date'),
    orderType: getFilteredSearchParam(searchParams, 'orderType', /[^a-zA-Z]/g, 'desc'),
    search: getFilteredSearchParam(searchParams, 'search', /[^a-zA-Z0-9 ]/g, undefined),
    startDate: startDateParams ? moment(startDateParams).toDate() : getDefaultStartDate(),
    endDate: endDateParams ? moment(endDateParams).toDate() : getDefaultEndDate(),
  };

  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
  const [modalNoteDetails, setModalNoteDetails] = useState({
    isOpen: false,
    note: '',
    title: '',
  });
  const [bodyFilter, setBodyFilter] = useState(INITIAL_VALUE_FILTER_BODY);
  const isFilterActive = !isAllPropertyObjectFalsy(bodyFilter);
  const { selectedRowKeys: selectedIds } = useSelector((state) => state.tools);

  const [modals, setModals] = useState({
    sort: false,
    limit: false,
    filter: false,
    export: false,
    updateStatus: false,
    result: false,
  });

  const { mutate: exportVisitorList, isLoading: isExporting } = useExportReportVisitor();
  const {
    data: userList,
    isLoading,
    isError,
    error,
  } = useReportBAVisitorList({
    filter: {
      ...queryFilter,
      startDate: moment(queryFilter.startDate).format('YYYY-MM-DD'),
      endDate: moment(queryFilter.endDate).format('YYYY-MM-DD'),
    },
    body: bodyFilter,
  });

  const TABLE_COLUMNS = useMemo(
    () => [
      {
        type: 'checkbox',
        isRightBorderAvailable: true,
        render: (record) => {
          return (
            <div className='form-check px-2'>
              <input
                className='form-check-input appearance-none h-4 w-4 border border-green rounded-sm bg-white checked:bg-green checked:border-green focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                type='checkbox'
                readOnly
              />
            </div>
          );
        },
      },

      {
        name: 'BA Name & Store',
        className: 'w-[30%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => (
          <div className='w-[30%] text-grey-70 text-sm font-medium'>
            <div class='flex items-center pl-2 xl:pl-5'>
              <div class='w-12'>
                <img src={record?.user_image || '/img/default-user.jpg'} class='w-12 h-12 object-cover' alt='User' />
              </div>
              <div class='w-56 pl-3 xl:pl-5'>
                <div class='overflow-hidden block text-ellipsis whitespace-nowrap text-sm font-semibold text-dark mb-1'>
                  <HighlightedText text={record?.user_name} highlight={queryFilter?.search} />
                </div>
                <div class='text-sm font-semibold text-green mb-1 overflow-hidden block text-ellipsis whitespace-nowrap'>
                  <HighlightedText text={record?.store_name} highlight={queryFilter?.search} />
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Tanggal Visit',
        className: 'w-[10%] text-sm font-semibold text-green',
        dataIndex: 'report_date_formatted',
        render: (record, report_date_formatted) => (
          <div class='w-[10%]'>
            <div class='text-sm font-medium  text-gray'>{report_date_formatted}</div>
          </div>
        ),
      },
      {
        name: 'Jml Pengunjung',
        className: 'w-[15%] text-sm font-semibold text-green text-center',
        dataIndex: 'total_visitor',
        render: (record, total_visitor) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium text-center text-gray'>{total_visitor?.toLocaleString()}</div>
          </div>
        ),
      },
      {
        name: 'Jml Trial Produk',
        className: 'w-[15%] text-sm font-semibold text-green text-center',
        dataIndex: 'total_trial',
        render: (record, total_trial) => (
            <div class='w-[15%]'>
              <div class='text-sm font-medium text-center text-gray'>{total_trial?.toLocaleString()}</div>
            </div>
        ),
      },
      {
        name: 'Jml Pembeli',
        className:
          'w-[15%] text-sm font-semibold text-center text-green overflow-hidden text-ellipsis whitespace-nowrap pl-7',
        dataIndex: 'total_buyer',
        render: (record, total_buyer) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium text-center text-gray'>{total_buyer?.toLocaleString()}</div>
          </div>
        ),
      },
      {
        name: 'Notes',
        className: 'w-[22%] text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap pl-7',
        dataIndex: 'note',
        render: (record, note) => (
          <div class='w-[22%] pl-7'>
            <div class='text-sm text-gray'>
              <span>{trucanateWords(note, 10)}</span>
              {isMaxWords(note, 10) && (
                <span
                  className='text-green font-bold cursor-pointer'
                  onClick={() =>
                    setModalNoteDetails({
                      isOpen: true,
                      note,
                      title: `Note Details (${record?.store_name})`,
                    })
                  }
                >
                  {' '}
                  See more
                </span>
              )}
            </div>
          </div>
        ),
      },
    ],
    [queryFilter.search]
  );
  const resetCheckList = () => {
    if (selectedIds.length > 0) {
      dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
    }
  };

  const updateQueryFilterAndNavigate = (updatedQueryFilter) => {
    setQueryFilter((prevState) => ({
      ...prevState,
      ...updatedQueryFilter,
    }));
    navigate({
      search: qs.stringify({ ...queryFilter, ...updatedQueryFilter }),
    });
  };

  const handleOnDatePickerChange = ({ start, end }) => {
    updateQueryFilterAndNavigate({
      startDate: start,
      endDate: end,
    });
  };

  const handleOnPageChange = (page) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    updateQueryFilterAndNavigate({ page });
  };

  const handleOnSortChange = ({ selected }) => {
    updateQueryFilterAndNavigate({
      orderBy: selected.sortBy,
      orderType: selected.sortType,
    });
    setModals({ ...modals, sort: false });
  };

  const handleOnLimitChange = ({ limit }) => {
    updateQueryFilterAndNavigate({ limit, page: 1 });
    setModals({ ...modals, limit: false, page: 1 });
  };

  const handleOnFilterChange = (selectedFilter) => {
    setBodyFilter(selectedFilter);
    setModals({ ...modals, filter: false });
    setQueryFilter((prevState) => ({
      ...prevState,
      page: 1,
    }));
    navigate({
      search: qs.stringify({
        ...queryFilter,
        page: 1,
      }),
    });
  };

  const handleOnSearch = (value) => {
    updateQueryFilterAndNavigate({ search: value.keyword, page: 1 });
  };

  const handleExport = () => {
    exportVisitorList(
      {
        queryFilter: {
          ...queryFilter,
          startDate: moment(queryFilter.startDate).format('YYYY-MM-DD'),
          endDate: moment(queryFilter.endDate).format('YYYY-MM-DD'),
        },
        bodyFilter: { ...bodyFilter, report_ids: selectedIds },
      },
      {
        onSuccess: (res) => {
          createAndClickLink(res.data.download_url);
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  useEffect(() => {
    resetCheckList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <div className='flex items-center w-full mb-5 justify-between'>
        <TableSearchInputComponent
          containerClassName='relative w-[30%] '
          placeholder='Cari user atau store...'
          initialValue={INITIAL_VALUE_FILTER_QUERY.search}
          inputProps={{
            disabled: isLoading,
          }}
          onSearch={handleOnSearch}
        />
        <div className='flex justify-start items-center gap-x-2'>
          <TableButtonSortComponent
            disabled={isLoading}
            onClick={() => {
              setModals((prev) => ({ ...prev, sort: true }));
            }}
          />
          <TableButtonFilterComponent
            disabled={isLoading}
            active={isFilterActive}
            onClick={() => {
              setModals((prev) => ({ ...prev, filter: true }));
            }}
          />
          <TableButtonLimitComponent
            disabled={isLoading}
            value={queryFilter.limit}
            onClick={() => setModals({ ...modals, limit: true })}
          />
          <TableButtonExport
            disabled={isLoading}
            loading={isExporting}
            onClick={() => {
              setModals({ ...modals, export: true });
            }}
          />
        </div>

        <div className='flex justify-between items-center gap-x-2'>
          <RangeDatePicker
            disabled={isLoading}
            iconInputClassName='icon-ico-calendar text-xl text-green absolute left-4 top-1'
            inputClassName='mx-1 w-full outline-none text-sm font-semibold text-center border border-gray-1 cursor-pointer  rounded-sm transition hover:border-green duration-150 ease-in-out border-solid py-2 px-3'
            initialStartDate={queryFilter.startDate}
            maxDate={moment().toDate()}
            initialEndDate={queryFilter.endDate}
            adjustDateOnChange={false}
            onChange={handleOnDatePickerChange}
          />
        </div>
      </div>

      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div className='italic text-dark text-sm mb-5'>{`Total ${userList?.data?.total_result} data`}</div>

          <BasicTableComponent
            rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
            columns={TABLE_COLUMNS}
            dataSource={userList?.data?.rows || []}
            loading={isLoading}
            error={isError}
            errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
            errorMessage={catchErrorMessage(error?.response?.data?.message)}
          />

          {userList?.data?.total_result === 0 && (
            <EmptyStateComponent
              message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
            />
          )}

          <Pagination
            currentPage={queryFilter.page}
            pageSize={queryFilter.limit}
            siblingCount={1}
            totalCount={userList?.data?.total_result || 0}
            onPageChange={handleOnPageChange}
          />
        </>
      )}
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={handleOnSortChange}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />

      <ModalTableLimitComponent
        onChange={handleOnLimitChange}
        initialValue={queryFilter.limit}
        visible={modals.limit}
        onClose={() => setModals({ ...modals, limit: false })}
      />
      <ModalConfirmation
        description='Apakah anda yakin ingin eksport data yang anda pilih ?'
        title='Export Data'
        loading={isExporting}
        visible={modals.export}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />

      <ModalResult
        description='Update status user berhasil dilakukan!'
        title='Update Status User'
        visible={modals.result}
        textConfirm='OK'
        onConfirm={() => setModals({ ...modals, result: false })}
        onClose={() => setModals({ ...modals, result: false })}
      />

      <ModalTableFilter
        initialValue={bodyFilter}
        filterStatusOptions={null}
        visible={modals.filter}
        onChange={handleOnFilterChange}
        onClose={() => setModals({ ...modals, filter: false })}
      />

      <ModalResult
        containerClassName='w-[530px] bg-white rounded-sm p-5 relative'
        visible={modalNoteDetails.isOpen}
        title={modalNoteDetails.title}
        description={modalNoteDetails.note}
        textConfirm='OK'
        onConfirm={() => setModalNoteDetails({ ...modalNoteDetails, isOpen: false })}
        onClose={() => setModalNoteDetails({ ...modalNoteDetails, isOpen: false })}
      >
        {(props) => (
          <>
            <p class='text-sm my-3 max-h-[340px] scroll'>{props.description}</p>
            <div class='flex justify-between items-center pb-2'>
              <div class='w-full'>
                <ButtonSubmit
                  loading={props.loading}
                  onClick={props.onConfirm}
                  title={props.textConfirm}
                  className='bg-green w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                />
              </div>
            </div>
          </>
        )}
      </ModalResult>
    </MainDashboardLayoutComponent>
  );
};

import moment from 'moment';
import { useMemo, useState } from 'react';
import { Tooltip } from 'flowbite-react';
import { useParams } from 'react-router-dom';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';

import { BasicTableComponent } from 'components/table/table-basic.component';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { PATH_CONSTANT } from 'config/path.constant';
import { ModalResult } from 'components/modal/modal.result.component';
import { useReportBASalesActionLog, useReportBASalesDetails } from 'api/report-ba/sales/sales.query.api';
import { catchErrorMessage, convertToRupiah } from 'ui-utils/string.utils';
import {
  useApproveReport,
  useExportSalesDetailReport,
  useUpdateKAENoteSalesReport
} from 'api/report-ba/sales/sales.mutation.api';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { ModalReportAttachmentComponent } from './modal-report-attachment.component';
import { ModalStockAudit } from 'pages/report-ba/cycle-count/cycle-count-details/modal-stock-audit.component';
import { AUDIT_STOCK_TYPE, useStockAuditData } from 'api/audit/audit-stock.query';
import {ModalNoteComponent} from "./modal-note.component";
const BREAD_CUMB_ITEMS = [
  {
    title: 'Report BA',
    path: PATH_CONSTANT.REPORT_BA.SALES,
  },
  {
    title: 'Sales Detail',
    path: PATH_CONSTANT.REPORT_BA.SALES,
  },
];

export const ReportBASalesDetailComponent = () => {
  const { recordId } = useParams();

  const [note, setNote] = useState('');
  const [modals, setModals] = useState({
    export: false,
    updateStatus: false,
    result: false,
    attachment: false,
    stockAudit: false,
    note: false
  });

  const { mutate: exportSalesDetailReport, isLoading: isExporting } = useExportSalesDetailReport();

  const { mutate: approveReport, isLoading: isApproving } = useApproveReport();
  const { mutate: updateNoteReport, isLoading: isUpdating } = useUpdateKAENoteSalesReport();
  const { data: salesDetails, isLoading, isError, error } = useReportBASalesDetails(recordId);
  const { data: salesDetailsActionLog } = useReportBASalesActionLog(recordId);
  const { data: stockAuditData } = useStockAuditData({
    recordId,
    type: AUDIT_STOCK_TYPE.SALES_REPORT,
  });

  const columns = useMemo(
    () => [
      {
        name: 'Product',
        className: 'w-[33%] text-sm font-semibold text-green text-center pr-3',
        dataIndex: 'user',
        render: (record) => (
          <div className='w-[33%] text-sm font-semibold text-green md:pr-1 pr-3'>
            <div class='flex items-center pl-2 xl:pl-5'>
              <div class='w-12'>
                <StandartImageComponent
                  src={record?.product_image || '/img/default-product.jpg'}
                  class='w-12 h-12 object-cover'
                  alt='Product'
                />
              </div>
              <div class='w-[calc(100%-3rem)] pl-3 xl:pl-5 line-clamp-3'>
                <Tooltip className='max-w-[30%]' content={record?.product_name}>
                  <div class='block text-xs font-semibold text-dark'>{record?.product_name}</div>
                </Tooltip>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Expired Date',
        className:
          'w-[13%] text-center text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap',
        dataIndex: 'expired_date_formatted',
        render: (record, expired_date_formatted) => (
          <div class='w-[13%]'>
            <div class='text-sm text-center font-medium  text-gray'>{expired_date_formatted}</div>
          </div>
        ),
      },
      {
        name: 'Harga Awal',
        className:
          'w-[14%] text-center text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap',
        dataIndex: 'product_price',
        render: (record, product_price) => (
          <div class='w-[14%] '>
            <div class='text-sm text-center text-gray overflow-hidden text-ellipsis whitespace-nowrap'>
              {convertToRupiah(product_price)}
            </div>
          </div>
        ),
      },
      {
        name: 'Harga Jual',
        className:
          'w-[14%] text-center text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap',
        dataIndex: 'selling_price',
        render: (record, selling_price) => (
          <div class='w-[14%]'>
            <div class='text-sm text-center text-gray overflow-hidden text-ellipsis whitespace-nowrap'>
              {convertToRupiah(selling_price)}
            </div>
          </div>
        ),
      },
      {
        name: 'QTY',
        className:
          'w-[9%] text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap text-center',
        dataIndex: 'qty_sold',
        render: (record, qty_sold) => (
          <div class='w-[9%]'>
            <div class='text-sm font-medium text-center text-gray'>{qty_sold.toLocaleString()}</div>
          </div>
        ),
      },
      {
        name: 'Total Sales',
        className:
          'w-[18%] text-sm font-semibold text-green text-center overflow-hidden text-ellipsis whitespace-nowrap ',
        dataIndex: 'total_sales',
        render: (record, total_sales) => (
          <div class='w-[18%] overflow-hidden'>
            <div class='text-sm font-medium text-gray text-center overflow-hidden text-ellipsis whitespace-nowrap'>
              {convertToRupiah(total_sales)}
            </div>
          </div>
        ),
      },
    ],
    []
  );

  const handleOnApproveReport = () => {
    approveReport(
      {
        recordId,
      },
      {
        onSuccess: () => {
          setModals({ ...modals, updateStatus: false, result: true });
        },
      }
    );
  };

  const handleSubmitKAENote = () => {
    if(!!note){
      let body = {admin_note : note}
      updateNoteReport(
          {
            recordId,
            body
          },
          {
            onSuccess: () => {
              setModals({ ...modals, note: false });
            },
          }
      );
    }
  }

  const handleExport = () => {
    exportSalesDetailReport(
      { recordId },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  const attachments = useMemo(() => {
    if (salesDetails) {
      return [
        ...salesDetails?.data?.report_attachments,
        ...salesDetails?.data?.report_images.map((item) => ({ ...item, file_url: item.image_url })),
      ];
    }
    return [];
  }, [salesDetails]);

  return (
    <MainDashboardLayoutComponent
      breadCumbs={BREAD_CUMB_ITEMS}
      isError={isError}
      errorTitle={error?.response?.data?.status}
      erroMessage={catchErrorMessage(error?.response?.data?.message)}
    >
      <div class='w-full flex items-center bg-gray-3 px-6 py-8 mb-5'>
        <div class='w-[calc(100%-375px)]'>
          <div class='flex items-center'>
            <div class='w-[300px] flex items-center'>
              <StandartImageComponent
                withZoom
                src={salesDetails?.data?.user?.profile_picture}
                class='overflow-hidden rounded-full w-[60px] h-[60px] border-2 border-solid border-green-3 object-cover'
              />
              <div class='w-[calc(100%-3rem)] pl-3'>
                <div class='pr-3 text-sm font-semibold text-green mb-2'>{salesDetails?.data?.user?.name}</div>
                <div class='overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray'>
                  {salesDetails?.data?.user?.phone}
                </div>
              </div>
            </div>
            <div class='w-[300px] flex items-center'>
              <StandartImageComponent
                defaultImage='/img/default-store.jpg'
                src={salesDetails?.data?.store?.image}
                class='overflow-hidden w-[60px] h-[60px] border-2 border-solid border-green-3'
              />
              <div class='w-[calc(100%-3rem)] pl-3'>
                <div class='pr-3 text-sm font-semibold text-green mb-2'>{salesDetails?.data?.store?.name}</div>
                <div class='overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray'>
                  {salesDetails?.data?.store?.city?.name}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='w-[375px] flex items-center'>
          <div class='flex items-center'>
            <span class='icon-ico-calendar text-[40px] text-green-3 font-normal'></span>
            {salesDetails?.data?.report_date && (
              <div class='w-[calc(100%-2rem)] pl-3'>
                <div class='overflow-hidden block text-ellipsis whitespace-nowrap text-sm font-bold text-black mb-2'>
                  Tanggal Penjualan
                </div>
                <div class='overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray'>
                  {moment(salesDetails?.data?.report_date).format('DD MMMM YYYY')}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div className='italic text-dark text-sm mb-5'>{`Total ${salesDetails?.data?.report_items?.length} items`}</div>
          <div className='flex w-full justify-between pb-8'>
            <div className='w-[calc(100%-290px)]'>
              <BasicTableComponent
                rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
                columns={columns}
                dataSource={salesDetails?.data?.report_items || []}
              />
              <div class='bg-gray-1 mt-3 rounded-sm py-2.5 px-2 flex items-center'>
                <div class='w-[74%]'>
                  <div class='block text-sm font-bold text-dark uppercase pl-[10%] xl:pl-[8%]'>Total</div>
                </div>
                <div class='w-[9%]'>
                  <div class='block text-sm font-bold text-dark text-center uppercase'>
                    {salesDetails?.data?.total_qty_sold.toLocaleString()}
                  </div>
                </div>
                <div class='w-[18%] overflow-hidden'>
                  <div class='block text-sm font-bold text-dark uppercase text-center overflow-hidden text-ellipsis whitespace-nowrap'>
                    {convertToRupiah(salesDetails?.data?.total_selling_price)}
                  </div>
                </div>
              </div>
            </div>
            <div className='w-[270px]'>
              <TableButtonExport
                  onClick={handleExport}
                  loading={isExporting}
                  title='Export Data'
                  className='outline-none w-full flex items-center border justify-center border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3 mb-3'
              />

              <div className='border border-solid border-gray-1 rounded-sm p-2 mb-3'>
                <div className='block text-sm font-semibold text-dark mb-3'>Approvement Report</div>
                {salesDetails?.data?.status === 'submitted' ? (
                    <button
                        className='bg-green flex justify-center items-center w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                        type='button'
                        onClick={() => {
                          setModals((prev) => ({
                            ...prev,
                            updateStatus: true,
                          }));
                        }}
                    >
                      <span className='icon-ico-apply mr-3 text-lg'></span> Approve
                    </button>
                ) : (
                    <div className='text-green font-semibold'>
                      <span className='icon-ico-apply  text-sm text-center'></span> Approved
                    </div>
                )}
              </div>

              <div className='bg-gray-3 py-5 px-4 mb-3'>
                <div className='block text-sm font-semibold text-dark mb-6'>Log Action</div>

                <ul class='step max-h-[45vh] scroll'>
                  {salesDetailsActionLog?.data?.map((actionLogItem) => (
                      <li>
                        <div className='bullet'></div>
                        <div className='text-sm font-semibold text-gray '>{actionLogItem?.action_description}</div>
                        <div
                            className='text-xs font-semibold italic text-gray mb-2'>{`Modified by ${actionLogItem?.actor_email}`}</div>
                        <div
                            className='text-sm font-medium italic text-gray'>{actionLogItem?.created_at_formatted}</div>
                      </li>
                  ))}
                </ul>
              </div>

              <div className='bg-gray-3 py-5 px-4 max-h-[45vh] scroll mb-3'>
                <div className='block text-sm font-semibold text-dark mb-3'>Notes KAE</div>
                <p className='text-gray text-sm mb-4 whitespace-pre-line'>{salesDetails?.data?.admin_note}</p>
                <button
                    className='bg-green flex  my-2 justify-center items-center w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                    type='button'
                    onClick={() => {
                      if (salesDetails?.data?.admin_note) {setNote(salesDetails?.data?.admin_note)}
                      setModals((prev) => ({...prev, note: true}))
                    }}
                >
                  {salesDetails?.data?.admin_note ? 'Edit KAE Notes' : 'Create KAE Notes'}
                </button>
              </div>

              <div className='bg-gray-3 py-5 px-4 max-h-[45vh] scroll'>
                <div className='block text-sm font-semibold text-dark mb-3'>Notes BA</div>
                <p className='text-gray text-sm mb-4 whitespace-pre-line'>{salesDetails?.data?.note}</p>

                {attachments.length > 0 && (
                    <button
                        className='outline-none w-full flex items-center border  border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
                        type='button'
                        onClick={() => {
                          setModals((prev) => ({
                            ...prev,
                            attachment: true,
                          }));
                        }}
                    >
                      <span className='icon-ico-attachment text-xl text-green'></span>
                      <span className='pl-2 font-semibold text-dark text-sm'>Attachment</span>
                    </button>
                )}

                <button
                    className='bg-green flex  my-2 justify-center items-center w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                    type='button'
                    onClick={() => {
                      setModals((prev) => ({
                        ...prev,
                        stockAudit: true,
                      }));
                    }}
                >
                  <span className='icon-ico-info mr-3 text-lg'></span> Stock Audit
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <ModalReportAttachmentComponent
          visible={modals.attachment}
          attachments={attachments}
          onClose={() => {
            setModals((prev) => ({...prev, attachment: false}));
          }}
      />

      <ModalStockAudit
          visible={modals.stockAudit}
          onClose={() => setModals({...modals, stockAudit: false})}
          data={stockAuditData?.data}
      />

      <ModalConfirmation
          description='Apakah anda yakin ingin menyetujui data report ini ?'
          title='Approve Report'
          imageIcon='/img/info.svg'
          visible={modals.updateStatus}
          loading={isApproving}
          textConfirm='Approve'
          onConfirm={handleOnApproveReport}
          onClose={() => setModals({...modals, updateStatus: false})}
      />

      <ModalResult
          description='Data report berhasil di setujui!'
          title='Approve Report'
          visible={modals.result}
          textConfirm='OK'
          onConfirm={() => setModals({...modals, result: false})}
          onClose={() => setModals({...modals, result: false})}
      />

      <ModalNoteComponent
        visible={modals.note}
        onClose={() => {
          setNote('')
          setModals((prev) => ({
            ...prev,
            note: false,
          }));
        }}
        note={note}
        onChange={(value) => setNote(value)}
        onSubmit={handleSubmitKAENote}
        loading={false}/>

    </MainDashboardLayoutComponent>
  );
};

import {useNavigate, useSearchParams} from "react-router-dom";
import {useMemo, useState} from "react";
import {useGetTimeOffRequestList} from "../../api/schedules/req-time-off/time-off.query.api";
import {MainDashboardLayoutComponent} from "../../components/main-layout/main.component";
import {PATH_CONSTANT} from "../../config/path.constant";
import qs from "query-string";
import {BounceLoading} from "../../components/loader/bounce.loading";
import {BasicTableComponent} from "../../components/table/table-basic.component";
import {catchErrorMessage} from "../../ui-utils/string.utils";
import {EmptyStateComponent} from "../../components/empty-data";
import {Pagination} from "../../components/table/table-pagination.component";
import {HighlightedText} from "../../components/highlighted-text";
import {TableSearchInputComponent} from "../../components/table/table-search.component";
import {TableButtonSortComponent} from "../../components/table/table-button-sort.component";
import {TableButtonLimitComponent} from "../../components/table/table-button-limit.component";
import {isAllPropertyObjectFalsy} from "../../ui-utils/object.utils";
import {ModalTableSortComponent} from "../../components/modal/modal-table-sort.component";
import {ModalTableLimitComponent} from "../../components/modal/modal-table-limit.component";
import {ModalTableFilter} from "../../components/modal/modal-table-filter.component";
import {TableButtonExport} from "../../components/table/table-button-export.component";
import {useExportTimeOffList, useUpdateTimeOffStatus} from "../../api/schedules/req-time-off/time-off.mutation.api";
import {ModalConfirmation} from "../../components/modal/moda.confirmation.component";
import {useDispatch, useSelector} from "react-redux";
import {Select} from "../../components/form/select.component";
import {ButtonSubmit} from "../../components/button/button-submit.component";
import {setToolsReducer} from "../../reducers/tools.reducer";
import {TableButtonFilterComponent} from "../../components/table/table-button-filter.component";
import {ModalReportAttachmentComponent} from "../report-ba/sales/report-sales-details/modal-report-attachment.component";

const BREAD_CRUMB_ITEMS = [
    {
        title: 'Request Cuti',
        path: PATH_CONSTANT.CUTI.LIST,
    },
];

const SORT_OPTIONS = [
    {label: 'Tanggal Request Cuti Terbaru', sortType: 'desc', sortBy: 'created_at'},
    {label: 'Tanggal Request Cuti Terdahulu', sortType: 'asc', sortBy: 'created_at'},
];

const APPROVE_OPTIONS = [
    {
        id: 'approved',
        name: 'Approve',
    },
    {
        id: 'rejected',
        name: 'Reject',
    },
];

const STATUS_CLASSNAME = {
    REJECTED: 'inline-block bg-red-status text-center w-28 py-1 px-1 rounded-sm text-red-1 text-sm font-medium',
    APPROVED: 'inline-block bg-green-status text-center w-28 py-1 px-1 rounded-sm text-green text-sm font-medium',
    WAITING_APPROVAL:
        'inline-block bg-yellow-light text-center w-28 py-1 px-1 rounded-sm text-yellow text-sm font-medium',
    CANCELLED:
        'inline-block bg-blue-light text-center w-28 py-1 px-1 rounded-sm text-blue text-sm font-medium',
};

const STATUS_LABEL = {
    REJECTED: 'Rejected',
    APPROVED: 'Approved',
    WAITING_APPROVAL: 'Waiting Approvement',
    CANCELLED: 'Cancelled',
};

const INITIAL_VALUE_FILTER_BODY = {
    status: null,
    paid_leave_type_id: null,
    paid_leave_ids: []
};

export const TimeOffList = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [modals, setModals] = useState({
        sort: false,
        limit: false,
        filter: false,
        export: false,
        updateStatus: false,
        result: false,
        attachment: false
    });
    const {selectedRowKeys: selectedIds} = useSelector((state) => state.tools);
    const [selectStatus, setSelectStatus] = useState(null);
    const [attachments, setAttachments] = useState([])
    const [note, setNote] = useState(null)

    const INITIAL_VALUE_FILTER_QUERY = {
        limit: searchParams.get('limit')?.replace(/[^0-9]/g, '') || 10,
        page: searchParams.get('page')?.replace(/[^0-9]/g, '') || 1,
        orderBy: searchParams.get('orderBy')?.replace(/[^a-zA-Z_]/g, '') || 'created_at',
        orderType: searchParams.get('orderType')?.replace(/[^a-zA-Z]/g, '') || 'desc',
        search: searchParams.get('search')?.replace(/[^a-zA-Z0-9 ]/g, '') || undefined,
    };
    const [bodyFilter, setBodyFilter] = useState(INITIAL_VALUE_FILTER_BODY);

    const isFilterActive = !isAllPropertyObjectFalsy(bodyFilter?.status);

    const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);

    const {mutate: exportTimeOffList, isLoading: isExporting} = useExportTimeOffList();
    const {mutate: updateRequestTimeOff, isLoading: isUpdating} = useUpdateTimeOffStatus();

    const {
        data: timeOffList,
        isLoading,
        isError,
        error,
    } = useGetTimeOffRequestList({
        filter: queryFilter,
        bodyFilter: bodyFilter,
    });

    const columns = useMemo(
        () => [
            {
                type: 'checkbox',
                isRightBorderAvailable: true,
                render: (record) => {
                    return (
                        <div className='form-check px-2'>
                            <input
                                className='form-check-input appearance-none h-4 w-4 border border-green rounded-sm bg-white checked:bg-green checked:border-green focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                                type='checkbox'
                                readOnly
                            />
                        </div>
                    );
                },
            },
            {
                name: 'User',
                className: 'w-[30%] text-sm font-semibold text-green pl-6',
                dataIndex: 'user',
                render: (record) => (
                    <div className='w-[30%] text-grey-70 text-sm font-medium'>
                        <div class='flex items-center pl-2 '>
                            <div class='w-[17%]'>
                                <img
                                    src={record?.user_image || '/img/default-user.jpg'}
                                    class='w-full h-12 object-cover'
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '/img/default-user.jpg';
                                    }}
                                    alt='User'
                                />
                            </div>
                            <div class='w-[83%] pl-3'>
                                <div
                                    class=' text-ellipsis whitespace-nowrap line-clamp-2 text-sm font-medium text-gray'>
                                    <HighlightedText text={record?.user_email} highlight={queryFilter?.search}/>
                                </div>
                            </div>
                        </div>
                    </div>
                ),
            },
            {
                name: 'Tipe Cuti',
                className: 'w-[15%] text-sm font-semibold text-green',
                dataIndex: 'user',
                render: (record) => (
                    <div class='w-[15%]'>
                        <div
                            class='text-sm font-medium text-gray'>{record?.type}</div>
                    </div>
                ),
            },
            {
                name: 'Tanggal Cuti',
                className: 'w-[20%] text-sm font-semibold text-green pl-4',
                dataIndex: 'user',
                render: (record) => (
                    <div class='w-[20%] pl-4'>
                        <div
                            class='text-sm font-medium text-gray'>{record?.paid_leave_date}</div>
                    </div>
                ),
            },
            {
                name: 'Total Hari',
                className: 'w-[15%] text-sm font-semibold text-green pl-4',
                dataIndex: 'user',
                render: (record) => (
                    <div class='w-[15%]'>
                        <div
                            class='text-sm font-medium text-gray pl-4'>{record?.total_days} hari
                        </div>
                    </div>
                ),
            },
            {
                name: 'Status',
                className: 'w-[15%] text-sm font-semibold text-green',
                dataIndex: 'user',
                render: (record) => (
                    <div class='w-[15%]'>
                        <div className={STATUS_CLASSNAME?.[record?.status.toUpperCase()]}>
                            {STATUS_LABEL?.[record?.status.toUpperCase()]}
                        </div>
                    </div>
                ),
            },
            {
                name: 'Catatan',
                className: 'w-[30%] text-sm font-semibold text-green text-center',
                dataIndex: 'user',
                render: (record) => (
                    <div class='w-[30%] flex flex-wrap pl-5'>
                        <div>
                            {record?.note}
                            {
                                record?.attachments?.length > 0 &&
                                <div onClick={() => {
                                    setNote(record?.note)
                                    setAttachments(record?.attachments)
                                    setModals({...modals, attachment: true});
                                }}
                                     className={'mt-2 text-sm italic text-gray-500'}>{record?.attachments?.length} attachments</div>
                            }
                        </div>
                    </div>
                ),
            },
        ],
        [queryFilter?.search]
    );

    const FILTER_OPTIONS = useMemo(() => {
        return [
            {
                key: 'status',
                type: 'checkbox',
                multiple: false,
                searchFilter: false,
                options: [
                    {
                        label: 'Waiting Approvement',
                        value: 'waiting_approval',
                    },
                    {
                        label: 'Approved',
                        value: 'approved',
                    },
                    {
                        label: 'Rejected',
                        value: 'rejected',
                    },
                    {
                        label: 'Cancelled',
                        value: 'cancelled',
                    },
                ],
            },
        ];
    }, []);

    const handleExport = () => {
        exportTimeOffList(
            {
                queryFilter: queryFilter,
                bodyFilter: {...bodyFilter, paid_leave_ids: selectedIds},
            },
            {
                onSuccess: (res) => {
                    const link = document.createElement('a');
                    link.href = res.data.download_url;
                    document.body.appendChild(link);
                    link.click();
                    setModals({...modals, export: false});
                },
            }
        );
    };

    const handleUpdateTimeOffStatus = () => {
        updateRequestTimeOff(
            {
                paid_leave_ids: selectedIds,
                status: selectStatus.toLowerCase(),
            },
            {
                onSuccess: () => {
                    setModals({...modals, updateStatus: false, result: true});
                    resetCheckList();
                },
            }
        );
    }

    const resetCheckList = () => {
        if (selectedIds.length > 0) {
            dispatch(setToolsReducer({selectedRowKeys: [], selectedRows: []}));
        }
    };


    return (
        <MainDashboardLayoutComponent breadCumbs={BREAD_CRUMB_ITEMS}>
            <div className='flex items-center mb-5'>
                <div className='w-[calc(100%-340px)] flex items-center justify-between'>
                    <TableSearchInputComponent
                        initialValue={INITIAL_VALUE_FILTER_QUERY.search}
                        inputProps={{
                            disabled: isLoading,
                        }}
                        onSearch={(value) => {
                            setQueryFilter({...queryFilter, search: value.keyword, page: 1});
                            navigate({
                                search: qs.stringify({
                                    ...queryFilter,
                                    search: value.keyword || undefined,
                                    page: 1,
                                }),
                            });
                        }}
                    />

                    <div
                        className='xl:w-[calc(100%-35%)] lg:w-[calc(100%-30%)] md:w-[calc(100%-23%)] flex xl:justify-start lg:justify-between md:justify-between items-center'>
                        <TableButtonSortComponent
                            disabled={isLoading}
                            onClick={() => {
                                setModals((prev) => ({...prev, sort: true}));
                            }}
                        />
                        <TableButtonFilterComponent
                            active={isFilterActive}
                            disabled={isLoading}
                            onClick={() => {
                                setModals((prev) => ({...prev, filter: true}));
                            }}
                        />
                        <TableButtonLimitComponent
                            disabled={isLoading}
                            value={queryFilter.limit}
                            onClick={() => setModals({...modals, limit: true})}
                        />
                        <TableButtonExport
                            disabled={isLoading}
                            loading={isExporting}
                            onClick={() => {
                                setModals({...modals, export: true});
                            }}
                        />
                    </div>
                </div>
                <div className='w-[340px] flex items-center pl-[60px] justify-between'>
                    <div className='w-[170px]'>
                        <Select
                            disabled={isLoading}
                            withNativeDownIcon
                            containerClassName={undefined}
                            onChange={(e) => {
                                setSelectStatus(e.target.value);
                            }}
                            placeholder='Select Action'
                            options={APPROVE_OPTIONS}
                            value={selectStatus}
                        />
                    </div>
                    <ButtonSubmit
                        title='Apply'
                        loading={isUpdating}
                        disabled={isLoading || !selectStatus || selectedIds.length === 0}
                        onClick={() => {
                            setModals({...modals, updateStatus: true});
                        }}
                    />
                </div>
            </div>

            {isLoading ?
                <div className='h-[70vh] flex items-center'>
                    <BounceLoading color='#5E755A'/>
                </div>
                :
                <>
                    <div
                        className='italic text-dark text-sm mb-5'>{`Total ${timeOffList?.data?.total_result} data`}</div>
                    <BasicTableComponent
                        rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
                        columns={columns}
                        dataSource={timeOffList?.data?.rows || []}
                        loading={isLoading}
                        error={isError}
                        errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
                        errorMessage={catchErrorMessage(error?.response?.data?.message)}
                    />
                    {timeOffList?.data?.total_result === 0 && (
                        <EmptyStateComponent
                            message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
                        />
                    )}
                    <Pagination
                        currentPage={queryFilter.page}
                        pageSize={queryFilter.limit}
                        siblingCount={1}
                        totalCount={timeOffList?.data?.total_result || 0}
                        onPageChange={(page) => {
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                            setQueryFilter({...queryFilter, page});
                            navigate({
                                search: qs.stringify({
                                    ...queryFilter,
                                    page,
                                }),
                            });
                        }}
                    />
                </>
            }
            <ModalTableSortComponent
                options={SORT_OPTIONS}
                initialValue={SORT_OPTIONS.find(
                    (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
                )}
                onChange={({selected}) => {
                    setQueryFilter((prevState) => ({
                        ...prevState,
                        orderBy: selected.sortBy,
                        orderType: selected.sortType,
                    }));
                    navigate({
                        search: qs.stringify({
                            ...queryFilter,
                            orderBy: selected.sortBy,
                            orderType: selected.sortType,
                        }),
                    });
                    setModals({...modals, sort: false});
                }}
                visible={modals.sort}
                onClose={() => setModals({...modals, sort: false})}
            />
            <ModalTableLimitComponent
                onChange={({limit}) => {
                    setQueryFilter((prevState) => ({
                        ...prevState,
                        limit,
                        page: 1,
                    }));
                    navigate({
                        search: qs.stringify({
                            ...queryFilter,
                            limit,
                            page: 1,
                        }),
                    });
                    setModals({...modals, limit: false});
                }}
                initialValue={queryFilter.limit}
                visible={modals.limit}
                onClose={() => setModals({...modals, limit: false})}
            />
            <ModalTableFilter
                initialValue={bodyFilter}
                visible={modals.filter}
                filterOptionsProps={FILTER_OPTIONS}
                onChange={(selectedFilter) => {
                    setBodyFilter(selectedFilter);
                    setQueryFilter((prevState) => ({
                        ...prevState,
                        page: 1,
                    }));
                    navigate({
                        search: qs.stringify({
                            ...queryFilter,
                            page: 1,
                        }),
                    });
                    setModals({...modals, filter: false});
                }}
                onClose={() => setModals({...modals, filter: false})}
            />
            <ModalConfirmation
                description='Apakah anda yakin ingin eksport data request cuti yang anda pilih?'
                title='Export Data'
                visible={modals.export}
                onConfirm={handleExport}
                onClose={() => setModals({...modals, export: false})}
            />
            <ModalConfirmation
                description='Apakah anda yakin ingin mengubah status request cuti yang anda pilih?'
                title='Update Request Cuti'
                imageIcon='/img/info.svg'
                visible={modals.updateStatus}
                textConfirm='Update'
                onConfirm={handleUpdateTimeOffStatus}
                onClose={() => setModals({...modals, updateStatus: false})}
            />
            <ModalTableFilter
                initialValue={bodyFilter}
                visible={modals.filter}
                filterOptionsProps={FILTER_OPTIONS}
                onChange={(selectedFilter) => {
                    setBodyFilter(selectedFilter);
                    setQueryFilter((prevState) => ({
                        ...prevState,
                        page: 1,
                    }));
                    navigate({
                        search: qs.stringify({
                            ...queryFilter,
                            page: 1,
                        }),
                    });
                    setModals({...modals, filter: false});
                }}
                onClose={() => setModals({...modals, filter: false})}
            />
            <ModalReportAttachmentComponent
                title={'Time Off Request Note'}
                visible={modals.attachment}
                note={note}
                attachments={attachments}
                onClose={() => {
                    setNote(null)
                    setAttachments([])
                    setModals((prev) => ({...prev, attachment: false}));
                }}
            />
        </MainDashboardLayoutComponent>
    )
}

import qs from 'query-string';
import { Tooltip } from 'flowbite-react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonLimitComponent } from 'components/table/table-button-limit.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { TableButtonFilterComponent } from 'components/table/table-button-filter.component';
import { Select } from 'components/form/select.component';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { Pagination } from 'components/table/table-pagination.component';
import { EmptyStateComponent } from 'components/empty-data';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { ModalTableLimitComponent } from 'components/modal/modal-table-limit.component';
import { ModalTableFilter } from 'components/modal/modal-table-filter.component';
import { isAllPropertyObjectFalsy } from 'ui-utils/object.utils';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { setToolsReducer } from 'reducers/tools.reducer';
import { PATH_CONSTANT } from 'config/path.constant';
import { ModalResult } from 'components/modal/modal.result.component';
import { catchErrorMessage } from 'ui-utils/string.utils';
import { useGetChangeScheduleRequestList } from 'api/schedules/req-ganti-jadwal/change-schedule.query.api';
import {
  useExportScheduleRequestsList,
  useUpdateScheduleRequests,
} from 'api/schedules/req-ganti-jadwal/change-schedule.mutation.api';
import { HighlightedText } from 'components/highlighted-text';
const APPROVE_OPTIONS = [
  {
    id: 'approved',
    name: 'Approve',
  },
  {
    id: 'rejected',
    name: 'Reject',
  },
];
const BREAD_CUMB_ITEMS = [
  {
    title: 'Jadwal & Presensi',
    path: PATH_CONSTANT.GANTI_JADWAL.LIST,
  },
  {
    title: 'Request Penggantian Jadwal',
    path: PATH_CONSTANT.GANTI_JADWAL.LIST,
  },
];

const INITIAL_VALUE_FILTER_BODY = {
  status: [],
};

const STATUS_CLASSNAME = {
  REJECTED: 'inline-block bg-red-status text-center w-28 py-1 px-1 rounded-sm text-red-1 text-sm font-medium',
  APPROVED: 'inline-block bg-green-status text-center w-28 py-1 px-1 rounded-sm text-green text-sm font-medium',
  WAITING_APPROVEMENT:
    'inline-block bg-yellow-light text-center w-28 py-1 px-1 rounded-sm text-yellow text-sm font-medium',
};

const STATUS_LABEL = {
  REJECTED: 'Rejected',
  APPROVED: 'Approved',
  WAITING_APPROVEMENT: 'Waiting Approvement',
};

const SORT_OPTIONS = [
  { label: 'Urutkan Nama A-Z', sortType: 'asc', sortBy: 'user_name' },
  { label: 'Urutkan Nama Z-A', sortType: 'desc', sortBy: 'user_name' },
  { label: 'Tanggal Cuti Terbaru - Terdahulu', sortType: 'desc', sortBy: 'initial_schedule' },
  { label: 'Tanggal Cuti Terdahulu - Terbaru', sortType: 'asc', sortBy: 'initial_schedule' },
  { label: 'Tanggal Pengganti Terbaru - Terdahulu', sortType: 'desc', sortBy: 'replacement_schedule' },
  { label: 'Tanggal Pengganti Terdahulu - Terbaru', sortType: 'asc', sortBy: 'replacement_schedule' },
];

export const ChangeScheduleRequestListComponent = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const INITIAL_VALUE_FILTER_QUERY = {
    limit: searchParams.get('limit')?.replace(/[^0-9]/g, '') || 20,
    page: searchParams.get('page')?.replace(/[^0-9]/g, '') || 1,
    orderBy: searchParams.get('orderBy')?.replace(/[^a-zA-Z_]/g, '') || 'replacement_schedule',
    orderType: searchParams.get('orderType')?.replace(/[^a-zA-Z]/g, '') || 'desc',
    search: searchParams.get('search')?.replace(/[^a-zA-Z0-9 ]/g, '') || undefined,
  };

  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
  const [bodyFilter, setBodyFilter] = useState(INITIAL_VALUE_FILTER_BODY);

  const { selectedRowKeys: selectedIds } = useSelector((state) => state.tools);
  const [selectStatus, setSelectStatus] = useState(null);

  const [modals, setModals] = useState({
    sort: false,
    limit: false,
    filter: false,
    export: false,
    updateStatus: false,
    result: false,
  });

  const { mutate: exportScheduleRequestList, isLoading: isExporting } = useExportScheduleRequestsList();

  const { mutate: updateRequestChangeSchedule, isLoading: isUpdating } = useUpdateScheduleRequests();
  const {
    data: userList,
    isLoading,
    isError,
    error,
  } = useGetChangeScheduleRequestList({
    filter: queryFilter,
    bodyFilter: bodyFilter,
  });

  const columns = useMemo(
    () => [
      {
        type: 'checkbox',
        isRightBorderAvailable: true,
        render: (record) => {
          return (
            <div className='form-check px-2'>
              <input
                className='form-check-input appearance-none h-4 w-4 border border-green rounded-sm bg-white checked:bg-green checked:border-green focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                type='checkbox'
                readOnly
              />
            </div>
          );
        },
      },

      {
        name: 'User',
        className: 'w-[30%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => (
          <div className='w-[30%] text-grey-70 text-sm font-medium'>
            <div class='flex items-center pl-2 '>
              <div class='w-[17%]'>
                <img
                  src={record?.user_image || '/img/default-user.jpg'}
                  class='w-full h-12 object-cover'
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/img/default-user.jpg';
                  }}
                  alt='User'
                />
              </div>
              <div class='w-[83%] pl-3'>
                <Link
                  to={`${PATH_CONSTANT.GANTI_JADWAL.DETAILS}/${record?.id}`}
                  class='w-60   text-sm font-semibold text-dark hover:text-green mb-1'
                >
                  <Tooltip className='max-w-[30%]' content={record?.user_name}>
                    <HighlightedText text={record?.user_name} highlight={queryFilter?.search} />
                  </Tooltip>
                </Link>
                <div class=' text-ellipsis whitespace-nowrap line-clamp-2 text-sm font-medium text-gray'>
                  <HighlightedText text={record?.user_email} highlight={queryFilter?.search} />
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Tanggal Cuti',
        className: 'w-[15%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium text-gray text-center'>{record?.initial_schedule_formatted}</div>
          </div>
        ),
      },
      {
        name: 'Tanggal Pengganti',
        className: 'w-[15%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium text-gray text-center'>{record?.replacement_schedule_formatted}</div>
          </div>
        ),
      },
      {
        name: 'Status',
        className: 'w-[15%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[15%] flex justify-center'>
            <div class={STATUS_CLASSNAME?.[record?.status.toUpperCase()]}>
              {STATUS_LABEL?.[record?.status.toUpperCase()]}
            </div>
          </div>
        ),
      },
      {
        name: '',
        className: 'w-[10%] text-sm font-semibold text-green',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[10%] flex justify-center'>
            <Link
              to={`${PATH_CONSTANT.GANTI_JADWAL.DETAILS}/${record?.id}`}
              class='w-48 text-center text-ellipsis line-clamp-2 whitespace-normal text-sm font-semibold text-green mb-1'
            >
              LIHAT DETAIL
            </Link>
          </div>
        ),
      },
    ],
    [queryFilter?.search]
  );

  const FILTER_OPTIONS = useMemo(() => {
    return [
      {
        key: 'status',
        type: 'checkbox',
        multiple: true,
        searchFilter: false,
        options: [
          {
            label: 'Waiting Approvement',
            value: 'waiting_approvement',
          },
          {
            label: 'Approved',
            value: 'approved',
          },
          {
            label: 'Rejected',
            value: 'rejected',
          },
        ],
      },
    ];
  }, []);

  const isFilterActive = !isAllPropertyObjectFalsy(bodyFilter);

  const resetCheckList = () => {
    if (selectedIds.length > 0) {
      dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
    }
  };

  const handleOnupdateRequestChangeSchedule = () => {
    updateRequestChangeSchedule(
      {
        schedule_change_request_ids: selectedIds,
        status: selectStatus.toLowerCase(),
      },
      {
        onSuccess: () => {
          setModals({ ...modals, updateStatus: false, result: true });
          resetCheckList();
        },
      }
    );
  };
  const handleExport = () => {
    exportScheduleRequestList(
      {
        queryFilter: queryFilter,
        bodyFilter: { ...bodyFilter, schedule_change_request_ids: selectedIds },
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  useEffect(() => {
    resetCheckList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <div className='flex items-center mb-5'>
        <div className='w-[calc(100%-340px)] flex items-center justify-between'>
          <TableSearchInputComponent
            initialValue={INITIAL_VALUE_FILTER_QUERY.search}
            inputProps={{
              disabled: isLoading,
            }}
            onSearch={(value) => {
              setQueryFilter({ ...queryFilter, search: value.keyword, page: 1 });
              navigate({
                search: qs.stringify({
                  ...queryFilter,
                  search: value.keyword || undefined,
                  page: 1,
                }),
              });
            }}
          />

          <div className='xl:w-[calc(100%-35%)] lg:w-[calc(100%-30%)] md:w-[calc(100%-23%)] flex xl:justify-start lg:justify-between md:justify-between items-center'>
            <TableButtonSortComponent
              disabled={isLoading}
              onClick={() => {
                setModals((prev) => ({ ...prev, sort: true }));
              }}
            />
            <TableButtonFilterComponent
              active={isFilterActive}
              disabled={isLoading}
              onClick={() => {
                setModals((prev) => ({ ...prev, filter: true }));
              }}
            />
            <TableButtonLimitComponent
              disabled={isLoading}
              value={queryFilter.limit}
              onClick={() => setModals({ ...modals, limit: true })}
            />
            <TableButtonExport
              disabled={isLoading}
              loading={isExporting}
              onClick={() => {
                setModals({ ...modals, export: true });
              }}
            />
          </div>
        </div>
        <div className='w-[340px] flex items-center pl-[60px] justify-between'>
          <div className='w-[170px]'>
            <Select
              disabled={isLoading}
              withNativeDownIcon
              containerClassName={undefined}
              onChange={(e) => {
                setSelectStatus(e.target.value);
              }}
              placeholder='Select Action'
              options={APPROVE_OPTIONS}
              value={selectStatus}
            />
          </div>
          <ButtonSubmit
            title='Apply'
            loading={isUpdating}
            disabled={isLoading || !selectStatus || selectedIds.length === 0}
            onClick={() => {
              setModals({ ...modals, updateStatus: true });
            }}
          />
        </div>
      </div>

      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div className='italic text-dark text-sm mb-5'>{`Total ${userList?.data?.total_result} data`}</div>

          <BasicTableComponent
            rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
            columns={columns}
            dataSource={userList?.data?.rows || []}
            loading={isLoading}
            error={isError}
            errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
            errorMessage={catchErrorMessage(error?.response?.data?.message)}
          />

          {userList?.data?.total_result === 0 && (
            <EmptyStateComponent
              message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
            />
          )}

          <Pagination
            currentPage={queryFilter.page}
            pageSize={queryFilter.limit}
            siblingCount={1}
            totalCount={userList?.data?.total_result || 0}
            onPageChange={(page) => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              setQueryFilter({ ...queryFilter, page });
              navigate({
                search: qs.stringify({
                  ...queryFilter,
                  page,
                }),
              });
            }}
          />
        </>
      )}
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={({ selected }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            orderBy: selected.sortBy,
            orderType: selected.sortType,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              orderBy: selected.sortBy,
              orderType: selected.sortType,
            }),
          });
          setModals({ ...modals, sort: false });
        }}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />

      <ModalTableLimitComponent
        onChange={({ limit }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            limit,
            page: 1,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              limit,
              page: 1,
            }),
          });
          setModals({ ...modals, limit: false });
        }}
        initialValue={queryFilter.limit}
        visible={modals.limit}
        onClose={() => setModals({ ...modals, limit: false })}
      />
      <ModalConfirmation
        description='Apakah anda yakin ingin eksport data request penggantian jadwal yang anda pilih?'
        title='Export Data'
        visible={modals.export}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />
      <ModalConfirmation
        description='Apakah anda yakin ingin mengubah status request perubahan jadwal yang anda pilih?'
        title='Update Request Ganti Jadwal'
        imageIcon='/img/info.svg'
        visible={modals.updateStatus}
        textConfirm='Update'
        onConfirm={handleOnupdateRequestChangeSchedule}
        onClose={() => setModals({ ...modals, updateStatus: false })}
      />

      <ModalResult
        description='Ganti jadwal dan update status req. Ganti jadwal berhasil!'
        title='Update Request Ganti Jadwal'
        visible={modals.result}
        textConfirm='OK'
        onConfirm={() => setModals({ ...modals, result: false })}
        onClose={() => setModals({ ...modals, result: false })}
      />

      <ModalTableFilter
        initialValue={bodyFilter}
        visible={modals.filter}
        filterOptionsProps={FILTER_OPTIONS}
        onChange={(selectedFilter) => {
          setBodyFilter(selectedFilter);
          setQueryFilter((prevState) => ({
            ...prevState,
            page: 1,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              page: 1,
            }),
          });
          setModals({ ...modals, filter: false });
        }}
        onClose={() => setModals({ ...modals, filter: false })}
      />
    </MainDashboardLayoutComponent>
  );
};

import clsx from 'clsx';
import {ModalContainer} from 'components/modal/modal-container.component';
import {ButtonSubmit} from "../../../../components/button/button-submit.component";
import {InputComponent} from "../../../../components/form/input.component";

export function ModalNoteComponent({ visible, onClose, onSubmit, note = '', title = 'KAE Notes', loading, onChange }) {
    return (
        <ModalContainer visible={visible}>
            <div id='show-item' class={clsx('w-[330px] bg-white rounded-sm p-5 relative', !visible && 'hidden')}>
                <div class='relative text-center py-4'>
                    <div class='mb-5 text-center font-bold text-sm'>{title}</div>
                    <button type='button' class='absolute top-0 right-0 border-0' onClick={onClose}>
                        <span class='icon-ico-close'></span>
                    </button>
                </div>

                <div className='max-h-[45vh] scroll mb-2'>
                    <div className='my-2'>
                        <InputComponent
                            disabled={loading}
                            label='Notes (optional)'
                            element='textarea'
                            rows={5}
                            placeholder='Enter some notes here...'
                            onChange={(e) => onChange(e.target.value)}
                            value={note}
                        />
                    </div>
                    <div className='flex justify-between items-center pb-2'>
                        <div className='w-[48%]'>
                            <ButtonSubmit
                                disabled={loading}
                                onClick={onClose}
                                title={'cancel'}
                                className='block w-full rounded-sm border border-solid border-gray-1 py-2 px-2 text-sm font-semibold text-dark hover:bg-green-status transition duration-75 ease-in-out'
                            />
                        </div>
                        <div className='w-[48%]'>
                            <ButtonSubmit
                                loading={loading}
                                onClick={() => onSubmit()}
                                title={'Submit'}
                                className='bg-green w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
}
